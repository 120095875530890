import { emptyToNull, nullToEmpty } from 'global-utils';

import axios from 'axios';
import moment from 'moment';

function transformResponse(employment) {
  return nullToEmpty({
    employmentId: employment.employment_id,
    contractType: employment.employment_contract_type_mc_id,
    startDate: moment(employment.start_date),
    endDate: moment(employment.end_date),
    signedOn: moment(employment.signed_on),
    terminatedOn: employment.terminated_on
      ? moment(employment.terminated_on)
      : '',
    employmentStatus: employment.employment_status,
    contractDocId: employment.contract_doc_id,
    availabilityHr: employment.availability_hr,
    payRate: employment.pay_rate,
    payFrequency: employment.pay_frequency_mc_id,
    transportType: employment.transport_type_mc_id,
    hasMileageAllowance: employment.has_mileage_allowance,
    mileageAllowance: employment.mileage_allowance,
    hasSeasonTicket: employment.has_season_ticket,
    seasonTicket: employment.season_ticket,
    createdDate: employment.created_date,
    createdBy: employment.created_by,
    hasMinWageConflict: employment.is_min_wage_conflict,
    revisionDate: moment(employment.revision_date),
  });
}

function transformRequest(employment) {
  return emptyToNull({
    employment: {
      employment_id: employment.employmentId,
      employment_contract_type_mc_id: employment.contractType,
      start_date: employment.startDate,
      end_date: employment.endDate,
      signed_on: employment.signedOn,
      employment_status: employment.employmentStatus,
      contract_doc_id: employment.contractDocId,
      availability_hr: employment.availabilityHr,
      pay_rate: Number(employment.payRate),
      pay_frequency_mc_id: employment.payFrequency,
      transport_type_mc_id: employment.transportType,
      has_mileage_allowance: employment.hasMileageAllowance,
      mileage_allowance: employment.mileageAllowance,
      has_season_ticket: employment.hasSeasonTicket,
      season_ticket: employment.seasonTicket,
      revision_date: employment.revisionDate,
    },
  });
}

async function getAll(cwUserName, params) {
  const url = `api/cw/${cwUserName}/employment`;
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function post(cwUserName, contractData, params) {
  const url = `api/cw/${cwUserName}/employment`;
  const request = transformRequest(contractData);
  const response = await axios.post(url, request, { params });

  return response.data.employment_id;
}

async function put(cwUserName, contractId, contractData) {
  const url = `api/cw/${cwUserName}/employment/${contractId}`;
  const request = transformRequest(contractData);
  await axios.put(url, request);
}

export { getAll, post, put, transformRequest, transformResponse };
