<template>
  <div v-loading="loading.bookingDetails">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Booking Details</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>
      <v-layout row class="pb-2">
        <v-flex xs4>
          <strong>Client Name:</strong>
        </v-flex>
        <v-flex xs8>
          {{ suName }}
        </v-flex>
      </v-layout>
      <v-layout row class="pb-2">
        <v-flex xs4>
          <strong>Booking Type:</strong>
        </v-flex>
        <v-flex xs8>
          {{ bookingTypeUtils.toLabel(booking.bookingType) }}
        </v-flex>
      </v-layout>
      <v-layout row class="pb-2">
        <v-flex xs4>
          <strong>Date:</strong>
        </v-flex>
        <v-flex xs8>
          {{ formatDate(booking.start) }}, {{ formatTime(booking.start) }} -
          {{ formatTime(booking.end) }}
        </v-flex>
      </v-layout>

      <v-layout row class="pb-2">
        <v-flex xs4>
          <strong>Address:</strong>
        </v-flex>
        <v-flex xs8>
          <v-layout justify-space-between>
            {{ suAddress }}
            <a target="__blank" :href="`https://www.google.com/maps?q=${suPostCode}`">
              <v-icon>directions</v-icon>
            </a>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row class="pb-2" v-if="consumablesText">
        <v-flex xs4>
          <strong>Consumables:</strong>
        </v-flex>
        <v-flex xs8 v-html="consumablesText"></v-flex>
      </v-layout>

      <v-layout row class="pb-2" v-if="keysafePin">
        <v-flex xs4>
          <strong>Keysafe:</strong>
        </v-flex>
        <v-flex xs8>
          {{ keysafePin }}
        </v-flex>
      </v-layout>

      <v-layout row class="pb-2" v-if="otherCwName">
        <v-flex xs4>
          <strong>Other CW:</strong>
        </v-flex>
        <v-flex xs8>
          {{ otherCwName }}
        </v-flex>
      </v-layout>
    </v-container>

    <hr class="mt--2" />

    <v-container fluid>
      <h6 class="title">Tasks</h6>
      <v-layout v-if="booking.tasks.length">
        <v-flex>
          <div class="mt-1" v-for="(task, index) in booking.tasks" :key="index">- {{ task.title }}</div>
        </v-flex>
      </v-layout>
      <v-layout v-else class="grey--text py-2"> No Tasks </v-layout>
    </v-container>
    <div v-if="booking.showFluidBalance">
      <hr class="mt--2" />
      <v-container fluid>
        <h6 class="title mb-2">Record Fluid Balance</h6>
      </v-container>
    </div>

    <div v-if="validMedications.length">
      <hr class="mt--2" />
      <v-container fluid>
        <h6 class="title mb-2">Medications</h6>
        <v-alert
          :value="true"
          v-for="medication in validMedications"
          :key="medication.medicationId"
          color="green lighten-5 success--text">
          <v-layout class="justify-space-between">
            <span class="semi-bold">
              {{ medication.medicationName }} {{ medication.strength }} ({{ medication.dosage }}
              {{ dosageUnitUtils.toLabel(medication.dosageUnit) }})
            </span>
            <span class="caption">
              {{ medicationTypeUtils.toLabel(medication.medicationType) }}
            </span>
          </v-layout>

          <v-layout class="caption justify-space-between">
            <span>
              <span
                v-for="i in medication.weekdays"
                :key="i"
                :class="{
                  'text--secondary': i !== weekday,
                  'text--default bold': i === weekday,
                }">
                {{ WEEKDAYS_SHORT[i] }}
              </span>
            </span>
            <span class="text--secondary">
              {{ medication.dayDisplay }}
            </span>
            <span>
              {{ mealPreferenceUtils.toLabel(medication.mealPreference) }}
            </span>
          </v-layout>
          <v-layout class="justify-space-between caption">
            <span
              v-for="(slot, i) in medication.slots"
              :key="i"
              :class="{
                'text--secondary': !slot.isActive,
                'error--text bold': slot.isActive && medication.isGrace,
                'text--default bold': slot.isActive && !medication.isGrace,
              }">
              {{ slot.startTime }}-{{ slot.endTime }}
            </span>
          </v-layout>
          <v-layout class="justify-space-between">
            <div class="caption">
              {{ medication.administrationDetails }}
            </div>
            <div v-if="medication.isGrace" class="error--text bold caption flex-shrink-0">Contact Office</div>
          </v-layout>
        </v-alert>
      </v-container>
    </div>

    <template v-if="hasFormAccess && isToday">
      <hr class="mt--2" />
      <v-container fluid>
        <v-btn
          class="ma-0"
          color="primary"
          flat
          small
          :href="`${NEW_FORM_SUBMISSION}/oba/${booking.suUserName}`"
          target="_blank">
          <v-icon class="mr-2">edit</v-icon>
          Fill Outcome Based Assessment Form
        </v-btn>
      </v-container>
    </template>
    <hr class="mt--1" />

    <v-container fluid grid-list-lg v-if="residencePhotos.length > 0">
      <v-layout row class="pa-2">
        <h6 class="title">Residence Photos</h6>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs6 v-for="(imageUrl, index) in residencePhotos" :key="index">
          <v-card>
            <a :href="imageUrl" target="_blank">
              <v-img :src="imageUrl" aspect-ratio class="grey darken-4"> </v-img>
            </a>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { currentUser } from 'auth-utils';
import { getOverlap, sortArrayBy } from 'global-utils';
import { NEW_FORM_SUBMISSION } from 'hcms-constants/APP_PATHS';
import {
  bookingTypeUtils,
  consumableTypeUtils,
  dosageUnitUtils,
  mealPreferenceUtils,
  medicationTypeUtils,
  titleUtils,
} from 'hcms-mc-utils';
import { get as getAttachment } from 'hcms-transforms/attachment';
import { get as getBooking } from 'hcms-transforms/booking';
import { getAll as getMedications } from 'hcms-transforms/su/medication';
import { getAll as getAddresses } from 'hcms-transforms/user/address';
import { get as getUser } from 'hcms-transforms/user_list';
import moment from 'moment';
import { VAlert, VCard, VImg, VToolbar, VToolbarTitle } from 'vuetify/lib';

async function getName(userName) {
  if (!userName) {
    return '';
  }
  const user = await getUser(userName);
  return `${titleUtils.toLabel(user.title)} ${user.firstName} ${user.lastName}`;
}

const WEEKDAYS_SHORT = moment.weekdaysShort(true).map((day) => day.slice(0, 3));

export default {
  name: 'BookingDetails',

  components: {
    VAlert,
    VToolbar,
    VToolbarTitle,
    VCard,
    VImg,
  },

  props: {
    bookingId: {
      required: true,
      type: Number,
    },
  },

  constants: {
    bookingTypeUtils,
    consumableTypeUtils,
    dosageUnitUtils,
    mealPreferenceUtils,
    medicationTypeUtils,
    WEEKDAYS_SHORT,
    NEW_FORM_SUBMISSION,
  },

  data() {
    return {
      loading: {
        bookingDetails: false,
      },

      booking: {
        suId: '',
        start: '',
        end: '',
        bookingAllocation: [],

        consumables: [],

        tasks: [],
      },

      medications: [],

      suAddress: '',
      suPostCode: '',
      suName: '',
      keysafePin: '',
      otherCwName: '',
      residencePhotos: [],
    };
  },

  computed: {
    isToday() {
      if (!this.booking.start) {
        return null;
      }

      const START_OF_DAY = moment().startOf('day');
      const END_OF_DAY = moment().endOf('day');

      return getOverlap([START_OF_DAY, END_OF_DAY], [this.booking.start, this.booking.end]);
    },
    consumablesText() {
      return this.booking.consumables
        .filter((consumable) => consumable.quantity > 0)
        .map(
          (consumable) =>
            `${consumable.quantity}&nbsp;x&nbsp;${consumableTypeUtils.toLabel(consumable.consumableType)}`,
        )
        .join(', ');
    },
    weekday() {
      return this.booking.start.weekday();
    },
    validMedications() {
      if (!this.booking.start) {
        return [];
      }

      const { start, medications } = this.booking;

      const activeMedicationIds = medications.map((m) => m.medicationId);
      const activeSlotIds = medications.map((m) => m.slotId);
      const graceSlotIds = medications.filter((m) => m.isGraceCovered).map((m) => m.slotId);

      const validMedications = this.medications
        .filter((medication) => activeMedicationIds.includes(medication.medicationId))
        .map((medication) => {
          const slots = medication.slots.map((slot) => ({
            ...slot,
            isActive: activeSlotIds.includes(slot.slotId),
            isGrace: graceSlotIds.includes(slot.slotId),
          }));

          return {
            ...medication,
            slots,
          };
        });

      return sortArrayBy(validMedications).map((medication) => ({
        ...medication,
        dayDisplay: `Bal: ${Math.ceil(Math.abs(medication.actualEnd.diff(start, 'days', true)))} day(s)`,
      }));
    },
  },

  methods: {
    async loadResidencePhotos(photoIds) {
      if (!this.booking.suUserName || !photoIds) {
        this.residencePhotos = [];
        return;
      }

      const promises = photoIds.map(getAttachment);
      const attachments = await Promise.all(promises);
      this.residencePhotos = attachments.map((attachment) => attachment.url);
    },

    async getSuData() {
      const NOW = moment();
      const addressPromise = getAddresses(this.booking.suUserName, {
        effective_from_lte: NOW.toISOString(),
        effective_until_gte: NOW.toISOString(),
        limit: 1,
      });
      const suNamePromise = getName(this.booking.suUserName);

      await Promise.all([addressPromise, suNamePromise]);

      const addresses = await addressPromise;
      const address = addresses[0];
      const suName = await suNamePromise;

      this.suName = suName;
      this.suAddress = [address.street1, address.street2, address.postCode].filter((val) => val).join(', ');
      this.suPostCode = address.postCode;
      this.keysafePin = address.keysafePin;
      this.loadResidencePhotos(address.residencePhotos);
    },
    async getMedications() {
      this.medications = await getMedications(this.booking.suUserName);
    },

    async getOtherCws() {
      const allocations = this.booking.bookingAllocation;
      if (allocations.length > 1) {
        const otherCwUserName = allocations
          .map((allocation) => allocation.cwUserName)
          .filter((cwUserName) => cwUserName !== currentUser.userName);
        this.otherCwUserName = await getName(otherCwUserName[0]);
      }
    },

    async init() {
      this.loading.bookingDetails = true;
      try {
        this.booking = await getBooking(this.bookingId);
        this.getOtherCws();
        this.getSuData();
        this.getMedications();
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading.bookingDetails = false;
    },
  },

  async created() {
    await this.init();
  },
};
</script>
