import './setup/checkAuthentication';
import './setup/locale';
import './setup/validator';
import './setup/axios';
import 'vuetify/src/stylus/app.styl';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'assets/scss/hcms.scss';

import Vuetify, {
  VApp,
  VBottomNav,
  VBtn,
  VContainer,
  VContent,
  VDivider,
  VFlex,
  VForm,
  VIcon,
  VLayout,
  VSpacer,
  VTextField,
  VTooltip,
} from 'vuetify/lib';
import { get as getConfig, isTruthy } from 'hcms-transforms/config';

import App from '@/App.vue';
import Cookie from 'js-cookie';
import GlobalUtils from 'global-utils';
import { LICENSE_PARAMS } from 'hcms-constants/CONFIG_TYPES';
import Loading from 'directives/Loading';
import Notify from 'utils/notify';
import ShowModal from 'vue-modal-promise';
import Vue from 'vue';
import VueConstants from 'vue-constants';
import { currentUser } from 'auth-utils';
import router from 'router';
import setDefaultTimezone from './setup/timezone';

Vue.use(VueConstants);
Vue.use(GlobalUtils);
Vue.use(ShowModal);
Vue.use(Notify);
Vue.use(Loading);

Vue.use(Vuetify, {
  components: {
    VApp,
    VBottomNav,
    VContent,
    VDivider,
    VForm,
    VLayout,
    VFlex,
    VTextField,
    VContainer,
    VBtn,
    VSpacer,
    VTooltip,
    VIcon,
  },
});

Vue.component('validator', {
  name: 'validator',
  props: ['value'],
  render(createElement) {
    return createElement('div', {
      style: 'display:none;',
    });
  },
});

Vue.config.productionTip = false;

Vue.prototype.userName = currentUser.userName;
Vue.prototype.showNav = Cookie.get('hideNav') !== 'true';
Vue.prototype.goBack = () => {
  try {
    router.go(-1);
  } catch (err) {
    router.push('/');
  }
};

const promises = [setDefaultTimezone(), getConfig([LICENSE_PARAMS])];

Promise.all(promises).then((responses) => {
  const licenseParams = responses[1][LICENSE_PARAMS];
  Vue.prototype.hasFormAccess = isTruthy(licenseParams.FORM_ACCESS);
  Vue.prototype.hasVmAccess = isTruthy(licenseParams.VM_ACCESS);
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    render: (h) => h(App),
  });
});
