<template>
  <v-card class="slot-details">
    <v-card-title>
      <div class="title">
        {{ title }}
      </div>
    </v-card-title>
    <v-card-text v-if="selectDate">
      <v-text-field
        name="Effective From"
        v-validate="validations.required"
        :error-messages="errors.first('Effective From')"
        v-model="effectiveFrom"
        label="Effective From"
        prepend-icon="calendar_today"
        @click="chooseDate()"
        readonly>
      </v-text-field>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn :color="color" flat @click="confirm()">
        Confirm
        <v-icon class="ml-2">check</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from 'moment';

import { VCard, VCardTitle, VCardText, VCardActions } from 'vuetify/lib';

import DateModal from '@/views/Common/DateModal.vue';

export default {
  name: 'ConfirmAvailability',

  components: {
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
  },

  props: {
    title: {
      required: true,
      type: String,
    },
    selectDate: {
      required: false,
      type: Boolean,
      default: false,
    },
    color: {
      required: false,
      type: String,
      default: 'success',
    },
  },

  constants: {
    validations: {
      required: 'required',
    },
  },

  data() {
    const CRITICAL_PERIOD_END = moment().endOf('week').add(1, 'week');
    const AFTER_CRITICAL_PERIOD = moment(CRITICAL_PERIOD_END).add(1, 'ms').format('YYYY-MM-DD');
    return {
      CRITICAL_PERIOD_END,
      AFTER_CRITICAL_PERIOD,
      effectiveFrom: AFTER_CRITICAL_PERIOD,
    };
  },

  methods: {
    async chooseDate() {
      if (this.newAvailability) {
        return;
      }

      const date = await this.$showModal({
        component: DateModal,
        props: {
          date: this.effectiveFrom,
          allowedDates: (checkDate) => checkDate > this.AFTER_CRITICAL_PERIOD,
        },
      });

      if (date) {
        this.effectiveFrom = moment(date).format('YYYY-MM-DD');
      }
    },

    async confirm() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      if (!this.selectDate) {
        this.$emit('close', true);
        return;
      }

      this.$emit('close', {
        effectiveFrom: moment(this.effectiveFrom, 'YYYY-MM-DD'),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.slot-details {
  width: 290px;
}
</style>
