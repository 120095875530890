import axios from 'axios';
import moment from 'moment';
import {
  transformRequest as transformSlotRequest,
  transformResponse as transformSlotResponse,
} from './availability_slot';

function transformResponse(avail) {
  // * format data and convert from snake_case
  return {
    availabilityId: avail.cw_availability_id,
    prevAvailabilityId: avail.prev_availability,
    effectiveFrom: moment(avail.effective_date),
    effectiveUntil: avail.end_date ? moment(avail.end_date) : '',
    status: avail.availability_status_mc_id,
    slots: avail.availability_slot.map(transformSlotResponse),

    createdDate: avail.created_date ? moment(avail.created_date) : '',
    createdBy: avail.created_by,
    modifiedBy: avail.last_modified_by,
    modifiedDate: avail.last_modified_date
      ? moment(avail.last_modified_date)
      : '',
  };
}

function transformRequest(avail) {
  // * format request and convert to snake_case
  return {
    availability: {
      availability_id: avail.availabilityId,
      prev_availability: avail.prevAvailabilityId,
      effective_date: avail.effectiveFrom,
      availability_status_mc_id: avail.status,
      availability_slot: avail.slots.map(transformSlotRequest),
    },
  };
}

async function getAll(cwUserName, params) {
  const url = `api/cw/${cwUserName}/availability`;
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function get(cwUserName, availabilityId) {
  const url = `api/cw/${cwUserName}/availability/${availabilityId}`;
  const response = await axios.get(url);

  return {
    ...transformResponse(response.data.availability),
    cwUserName,
  };
}

async function post(cwUserName, availability) {
  const url = `api/cw/${cwUserName}/availability`;
  const request = transformRequest(availability);
  const response = await axios.post(url, request);

  return response.data.availability_id;
}

async function put(cwUserName, availabilityId, availability) {
  const url = `api/cw/${cwUserName}/availability/${availabilityId}`;
  const request = transformRequest(availability);
  await axios.put(url, request);
}

export { get, getAll, post, put };
