<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>today</v-icon>
      <v-toolbar-title>Availability</v-toolbar-title>
    </v-toolbar>
    <div v-loading="loading" class="pa-3">
      <v-layout class="mb-3" justify-end align-center>
        <div v-if="hasPendingRequest">
          <router-link :to="{ name: 'Availability Request' }" tag="span">
            <v-btn flat class="ma-0" color="warning">
              View Request
              <v-icon class="ml-2">edit</v-icon>
            </v-btn>
          </router-link>
        </div>
        <div v-else-if="!hasUpcomingAvailability">
          <router-link :to="{ name: 'Change Availability' }" tag="span">
            <v-btn flat small color="primary">
              Edit
              <v-icon class="ml-2">edit</v-icon>
            </v-btn>
          </router-link>
        </div>
      </v-layout>
      <div>
        <div class="mb-3 warning--text" v-if="hasUpcomingAvailability">
          <v-icon class="warning--text mr-2">warning</v-icon>
          Availability will change on
          {{ formatDate(upcomingAvailability.effectiveFrom) }}
        </div>
        <resource-timeline v-bind="resourceTimelineProps" @eventClick="this.viewSlotDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import { availabilityStatusUtils, biweeklyUtils } from 'hcms-mc-utils';
import { getAll as getAvailabilities } from 'hcms-transforms/cw/availability';
import moment from 'moment';
import { ResourceTimeline } from 'vue-resource-timeline';
import { VToolbar, VToolbarTitle } from 'vuetify/lib';
import SlotDetails from './SlotDetails.vue';

const { ODD_WEEK, EVEN_WEEK } = biweeklyUtils.DICT;

const currentQuery = {
  status: availabilityStatusUtils.DICT.CURRENT,
};
const pendingQuery = {
  status: availabilityStatusUtils.DICT.PENDING,
};
const upcomingQuery = {
  status: availabilityStatusUtils.DICT.APPROVED,
};

const empty = {
  slots: [],
};

export default {
  name: 'Availability',

  components: {
    VToolbar,
    VToolbarTitle,
    ResourceTimeline,
  },

  data() {
    return {
      loading: false,
      currentAvailability: this.cloneJSON(empty),
      pendingAvailability: this.cloneJSON(empty),
      upcomingAvailability: this.cloneJSON(empty),
    };
  },

  computed: {
    resourceTimelineProps() {
      const getBiweeklyClass = (biweekly) => {
        if (biweekly === ODD_WEEK) {
          return 'rt__event--odd';
        }
        if (biweekly === EVEN_WEEK) {
          return 'rt__event--even';
        }
        return '';
      };
      const slotToEvent = (slot) => ({
        origSlot: slot,
        resourceId: slot.day,
        start: slot.startTime,
        end: slot.endTime,
        title: `${slot.startTime} - ${slot.endTime}`,
        class: getBiweeklyClass(slot.biweekly),
      });
      const weekDays = moment.weekdaysShort(true);

      const defaultClass = 'rt__resource--success  rt__resource--active';

      const eventSlots = this.currentAvailability.slots;
      const resourceClass = defaultClass;
      const resources = weekDays.map((day, index) => ({
        title: day,
        id: index,
        class: resourceClass,
      }));
      const events = eventSlots.map(slotToEvent);
      return {
        resources,
        events,
        class: 'rt--hide-add',
        showEmptyResource: true,
      };
    },
    hasPendingRequest() {
      return typeof this.pendingAvailability.availabilityId === 'number';
    },
    hasUpcomingAvailability() {
      return typeof this.upcomingAvailability.availabilityId === 'number';
    },
  },

  methods: {
    async getAvailabilities() {
      this.loading = true;
      try {
        const promises = [
          getAvailabilities(this.userName, currentQuery),
          getAvailabilities(this.userName, upcomingQuery),
          getAvailabilities(this.userName, pendingQuery),
        ];
        const responses = await Promise.all(promises);
        this.currentAvailability = responses[0].length ? responses[0][0] : this.cloneJSON(empty);
        this.upcomingAvailability = responses[1].length ? responses[1][0] : this.cloneJSON(empty);
        this.pendingAvailability = responses[2].length ? responses[2][0] : this.cloneJSON(empty);
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading = false;
    },

    viewSlotDetails(event) {
      const slot = event.origSlot;
      this.$showModal({
        component: SlotDetails,
        props: {
          prevSlot: slot,
          disabled: true,
        },
      });
    },
  },

  created() {
    this.getAvailabilities();
  },
};
</script>
