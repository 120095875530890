<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>settings</v-icon>
      <v-toolbar-title>More</v-toolbar-title>
      <v-spacer></v-spacer>
      <notification-bell></notification-bell>
    </v-toolbar>

    <v-flex class="my-4" layout justify-center>
      <v-avatar :size="120">
        <v-img :src="profileImageUrl" />
      </v-avatar>
    </v-flex>
    <v-flex class="my-2" layout justify-center>
      <h5 class="headline font-weight-regular">
        {{ titleUtils.toLabel(user.title) }} {{ user.firstName }}
        {{ user.lastName }}
      </h5>
    </v-flex>

    <v-list>
      <v-list-tile :to="{ name: 'Profile' }">
        <v-list-tile-avatar>
          <v-icon>account_box</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Profile </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :to="{ name: 'Availability' }">
        <v-list-tile-avatar>
          <v-icon>today</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Availability </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :to="{ name: 'Contacts' }">
        <v-list-tile-avatar>
          <v-icon>person_add</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Contacts </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :to="{ name: 'Preferences' }">
        <v-list-tile-avatar>
          <v-icon>playlist_add_check</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Booking Preferences </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :to="{ name: 'Trainings' }">
        <v-list-tile-avatar>
          <v-icon>verified_user</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Trainings </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :href="LOG_REQUEST_PATH">
        <v-list-tile-avatar>
          <v-icon>send</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Log Request </v-list-tile-content>
      </v-list-tile>

      <v-list-tile :href="`${NEW_FORM_SUBMISSION}/requests`">
        <v-list-tile-avatar>
          <v-icon>edit</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Submit Form </v-list-tile-content>
      </v-list-tile>

      <v-list-tile @click="logout">
        <v-list-tile-avatar>
          <v-icon>exit_to_app</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content> Logout </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </div>
</template>

<script>
import { logout } from 'auth-utils';
import NotificationBell from 'components/NotificationBell.vue';
import { LOG_REQUEST as LOG_REQUEST_PATH, NEW_FORM_SUBMISSION } from 'hcms-constants/APP_PATHS';
import { titleUtils } from 'hcms-mc-utils';
import { get as getAttachment } from 'hcms-transforms/attachment';
import { get as getUser } from 'hcms-transforms/user_list';
import {
  VAvatar,
  VImg,
  VList,
  VListTile,
  VListTileAvatar,
  VListTileContent,
  VToolbar,
  VToolbarTitle,
} from 'vuetify/lib';

export default {
  name: 'Misc',
  components: {
    NotificationBell,
    VAvatar,
    VList,
    VListTile,
    VListTileAvatar,
    VListTileContent,
    VToolbar,
    VToolbarTitle,
    VImg,
  },

  constants: {
    LOG_REQUEST_PATH,
    NEW_FORM_SUBMISSION,
    titleUtils,
  },

  data() {
    return {
      loading: false,
      user: {
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
      },
      profileImageUrl: '/img/no-photo-man.png',
    };
  },

  methods: {
    async logout() {
      await logout();
    },
  },

  async created() {
    this.loading = true;

    try {
      this.user = await getUser(this.userName);

      if (this.user.profileImageId) {
        const profileImageAttachment = await getAttachment(this.user.profileImageId);
        this.profileImageUrl = profileImageAttachment.url;
      }
    } catch (err) {
      this.$notify('Could not fetch personal info', 'error');
    }

    this.loading = false;
  },
};
</script>
