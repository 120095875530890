<template>
  <v-card class="slot-details label-on-disable">
    <v-card-title>
      <h3 class="title">Slot Details</h3>
    </v-card-title>
    <v-card-text>
      <v-text-field name="Day" label="Day" :value="dayName" disabled> </v-text-field>
      <v-text-field
        class="required"
        name="Start Time"
        v-validate="rules.startTime"
        :error-messages="errors.first('Start Time')"
        v-model="newSlot.startTime"
        label="Start Time"
        @click="selectStartTime()"
        :disabled="disabled"
        readonly>
      </v-text-field>
      <v-text-field
        class="required"
        name="End Time"
        v-validate="rules.endTime"
        :error-messages="errors.first('End Time')"
        v-model="newSlot.endTime"
        label="End Time"
        @click="selectEndTime()"
        :disabled="disabled"
        readonly>
      </v-text-field>
      <v-select
        class="required"
        :items="BIWEEKLY"
        item-text="label"
        item-value="value"
        name="Occurance"
        v-validate="rules.biweekly"
        :error-messages="errors.first('Occurance')"
        label="Occurance"
        :disabled="disabled"
        v-model="newSlot.biweekly">
      </v-select>
    </v-card-text>
    <v-card-actions class="justify-end" v-if="!disabled">
      <v-btn class="mr-2" color="error" icon flat v-if="isEdit" @click="removeSlot()">
        <v-icon>delete</v-icon>
      </v-btn>
      <v-btn color="primary" v-if="isEdit" flat @click="saveSlot()">
        Update
        <v-icon class="ml-2">save_alt</v-icon>
      </v-btn>
      <v-btn color="primary" v-else flat @click="saveSlot()">
        Add
        <v-icon class="ml-2">add</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from 'moment';

import { biweeklyUtils } from 'hcms-mc-utils';

import { VCard, VCardTitle, VCardText, VCardActions, VSelect } from 'vuetify/lib';

import TimeModal from '@/views/Common/TimeModal.vue';

const { EVERY_WEEK } = biweeklyUtils.DICT;

export default {
  name: 'SlotDetails',

  components: {
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VSelect,
  },

  props: {
    prevSlot: {
      required: true,
      type: Object,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
  },

  constants: {
    BIWEEKLY: biweeklyUtils.OPTIONS,
  },

  data() {
    return {
      newSlot: {
        day: '',
        startTime: '',
        endTime: '',
        biweekly: EVERY_WEEK,
        ...this.prevSlot,
      },
    };
  },

  computed: {
    rules() {
      return {
        startTime: 'required',
        endTime: {
          required: true,
          gt: [this.newSlot.startTime],
        },
        biweekly: 'required',
      };
    },
    isEdit() {
      return Boolean(this.prevSlot.startTime);
    },
    dayName() {
      return moment(this.prevSlot.day, 'e').format('dddd');
    },
  },

  methods: {
    async selectStartTime() {
      const time = await this.$showModal({
        component: TimeModal,
        props: {
          time: this.newSlot.startTime,
          allowedMinutes: (val) => val % 30 === 0,
        },
      });

      if (time) {
        this.newSlot.startTime = time;
      }
    },
    async selectEndTime() {
      const time = await this.$showModal({
        component: TimeModal,
        props: {
          time: this.newSlot.endTime,
          allowedMinutes: (val) => val % 30 === 0,
          min: this.newSlot.startTime,
        },
      });

      if (time) {
        this.newSlot.endTime = time;
      }
    },

    async saveSlot() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      this.$emit('close', { newSlot: this.cloneJSON(this.newSlot) });
    },

    async removeSlot() {
      this.$emit('close', { newSlot: null });
    },
  },

  created() {
    this.newSlot.startTime = this.prevSlot.startTime;
    this.newSlot.endTime = this.prevSlot.endTime;
  },
};
</script>
<style lang="scss">
.slot-details {
  width: 290px;
}
</style>
