import axios from 'axios';

function transformResponse(bank) {
  return {
    accountType: bank.account_type_mc_id,
    accountName: bank.account_name,
    accountNumber: bank.account_number,
    bankName: bank.bank_name,
    branchName: bank.branch_name,
    sortCode: bank.sort_code,
  };
}
function transformRequest(bank) {
  return {
    account_type_mc_id: bank.accountType,
    account_name: bank.accountName,
    account_number: bank.accountNumber,
    bank_name: bank.bankName,
    branch_name: bank.branchName,
    sort_code: bank.sortCode,
  };
}

async function get(userName) {
  const url = `api/cw/${userName}`;
  const response = await axios.get(url);

  return transformResponse(response.data.user.bank_details);
}

async function put(userName, bankDetails) {
  const url = `api/cw/${userName}`;
  const details = transformRequest(bankDetails);
  const request = {
    user: {
      bank_details: details,
    },
  };
  await axios.put(url, request);
}

export { get, put, transformResponse, transformRequest };
