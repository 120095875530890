<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Apply for Leave</v-toolbar-title>
    </v-toolbar>

    <v-container fluid class="form-grid py-3">
      <v-layout row>
        <v-flex xs12 class="required">
          <v-select
            :items="LEAVE_TYPES"
            item-text="label"
            name="Leave Type"
            v-validate="rules.leaveType"
            :error-messages="errors.first('Leave Type')"
            label="Leave Type"
            v-model="leave.leaveType">
          </v-select>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs6>
          <validator :value="leave.fromDate" v-validate="rules.fromDate" name="From Date"></validator>
          <v-text-field
            class="required"
            :error-messages="errors.first('From Date')"
            :value="formatDate(leave.fromDate)"
            label="From Date"
            prepend-inner-icon="event"
            @click="selectFromDate()"
            readonly>
          </v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-select
            :items="FROM_HALF_OPTIONS"
            item-value="value"
            item-text="label"
            :disabled="!leave.fromDate"
            label="From Half"
            v-model="fromTime">
          </v-select>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs6>
          <validator :value="leave.toDate" v-validate="rules.toDate" name="To Date"></validator>
          <v-text-field
            class="required"
            :error-messages="errors.first('To Date')"
            :value="formatDate(leave.toDate)"
            label="To Date"
            prepend-inner-icon="event"
            @click="selectToDate()"
            readonly>
          </v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-select
            :items="TO_HALF_OPTIONS"
            item-value="value"
            item-text="label"
            :disabled="!leave.toDate"
            label="To Half"
            v-model="toTime">
          </v-select>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12 class="required">
          <v-textarea
            name="Justification"
            v-validate="rules.reason"
            :error-messages="errors.first('Justification')"
            v-model="leave.reason"
            rows="1"
            auto-grow
            label="Justification">
          </v-textarea>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <h5 class="subheading">Attachments (if any)</h5>
          <v-btn class="mx-0" flat color="primary" @click="$refs.upload.click()">
            <v-icon class="mr-2">cloud_upload</v-icon>
            Upload
          </v-btn>
          <div class="body-1" v-if="files.length > 0">
            {{ files[0].name }}
            <v-btn small icon @click="removeFile()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <input type="file" @change="fileChange" v-show="false" ref="upload" />
        </v-flex>
      </v-layout>
    </v-container>

    <v-fab-transition>
      <v-btn absolute dark fab bottom right color="red" v-show="isEdited" @click="applyLeave">
        <v-icon>save</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import ConfirmationModal from '@/views/Common/ConfirmationModal.vue';
import DateModal from '@/views/Common/DateModal.vue';
import { leaveStatusUtils, leaveTypeUtils } from 'hcms-mc-utils';
import { post as postAttachment } from 'hcms-transforms/attachment';
import { post as postLeave } from 'hcms-transforms/cw/leave';
import moment from 'moment';
import { VFabTransition, VSelect, VTextarea, VToolbar, VToolbarTitle } from 'vuetify/lib';

const FROM_HALF_OPTIONS = [
  {
    value: '00:00:00',
    label: 'First Half',
  },
  {
    value: '12:00:00',
    label: 'Second Half',
  },
];

const TO_HALF_OPTIONS = [
  {
    value: '11:59:59',
    label: 'First Half',
  },
  {
    value: '23:59:59',
    label: 'Second Half',
  },
];

export default {
  name: 'NewLeave',
  components: {
    VToolbar,
    VToolbarTitle,
    VSelect,
    VTextarea,
    VFabTransition,
  },

  constants: {
    LEAVE_TYPES: leaveTypeUtils.OPTIONS,
    FROM_HALF_OPTIONS,
    TO_HALF_OPTIONS,
  },

  data() {
    return {
      leave: {
        leaveType: '',
        fromDate: '',
        toDate: '',
        reason: '',
        leaveDocId: '',
      },
      originalLeave: {},
      files: [],
    };
  },

  computed: {
    fromTime: {
      get() {
        return this.leave.fromDate ? moment(this.leave.fromDate).format('HH:mm:ss') : '';
      },
      set(value) {
        this.leave.fromDate = this.setTime(this.leave.fromDate, value);
      },
    },
    toTime: {
      get() {
        return this.leave.toDate ? moment(this.leave.toDate).format('HH:mm:ss') : '';
      },
      set(value) {
        this.leave.toDate = this.setTime(this.leave.toDate, value);
      },
    },
    rules() {
      return {
        leaveType: 'required',
        fromDate: {
          required: true,
          outsideCriticalPeriod: this.leave.leaveType === leaveTypeUtils.DICT.ANNUAL_LEAVE,
          insideCriticalPeriod: this.leave.leaveType === leaveTypeUtils.DICT.URGENT_LEAVE,
        },
        toDate: {
          required: true,
          isAfter: [this.leave.fromDate],
        },
        reason: 'required',
      };
    },
    baseEndpoint() {
      return `/api/cw/${this.userName}/leave`;
    },

    isEdited() {
      return !this.equalJSON(this.leave, this.originalLeave);
    },

    dateError() {
      return this.leave.fromDate && this.leave.toDate && this.leave.fromDate > this.leave.toDate;
    },

    TODAY() {
      return moment().format('YYYY-MM-DD');
    },
  },

  methods: {
    fromAllowedDates(date) {
      return date >= this.TODAY;
    },

    toAllowedDates(date) {
      return date >= this.TODAY;
    },

    async applyLeave() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      try {
        const leaveDocId = [];
        if (this.files.length > 0) {
          const attachment = await postAttachment(this.userName, {
            note: 'Care Worker Apply Leave Attachment',
            fileData: this.files[0],
          });
          leaveDocId.push(attachment.attachmentId);
        }

        await postLeave(this.userName, {
          ...this.leave,
          leaveDocId,
          leaveStatus: leaveStatusUtils.DICT.PENDING,
        });

        this.originalLeave = this.cloneJSON(this.leave);
        this.$notify('Leave application has been submitted', 'success');
        this.$router.push({
          name: 'Leave',
        });
      } catch (err) {
        this.$notify(err, 'error');
      }
    },

    async fileChange(event) {
      this.files = event.target.files;
    },

    removeFile() {
      this.files = [];
    },

    async selectFromDate() {
      const date = await this.$showModal({
        component: DateModal,
        props: {
          date: this.leave.fromDate ? this.leave.fromDate.format('YYYY-MM-DD') : '',
          allowedDates: this.fromAllowedDates,
        },
      });

      if (!date) {
        return;
      }

      this.leave.fromDate = moment(date);
    },

    async selectToDate() {
      const date = await this.$showModal({
        component: DateModal,
        props: {
          date: this.leave.toDate ? this.leave.toDate.format('YYYY-MM-DD') : '',
          allowedDates: this.toAllowedDates,
        },
      });

      if (!date) {
        return;
      }

      this.leave.toDate = moment(date).endOf('day');
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.isEdited) {
      next();
      return;
    }

    const response = await this.$showModal({
      component: ConfirmationModal,
      props: {
        description: "Your data hasn't been saved!\nDo you want to proceed?",
      },
    });
    if (response) {
      next();
    }
  },

  async created() {
    this.originalLeave = this.cloneJSON(this.leave);
  },
};
</script>

<style lang="scss" scoped>
.date-error {
  color: red;
}
</style>
