<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>map</v-icon>
      <v-toolbar-title>Bookings</v-toolbar-title>
      <v-spacer></v-spacer>
      <notification-bell></notification-bell>
    </v-toolbar>

    <v-layout align-center class="my-2">
      <v-btn icon @click="nextDate(-1)">
        <v-icon>chevron_left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-toolbar-title class="ml-0" @click="changeBookingDate()">
        <v-btn icon>
          <v-icon>date_range</v-icon>
        </v-btn>
        {{ formatDate(bookingDate) }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="nextDate(1)">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-layout>
    <bookings v-loading="loading" v-model="bookingList" :isSameDay="true" />
  </div>
</template>

<script>
import DateModal from '@/views/Common/DateModal.vue';
import Bookings from 'components/Bookings.vue';
import NotificationBell from 'components/NotificationBell.vue';
import { bookingAllocationStatusUtils, bookingStatusUtils } from 'hcms-mc-utils';
import { getAll as getBookings } from 'hcms-transforms/booking';
import moment from 'moment';
import { VToolbar, VToolbarTitle } from 'vuetify/lib';

const TODAY = new Date();

const { NEW, RESCHEDULE_REQUEST } = bookingStatusUtils.DICT;
const { ALLOCATED } = bookingAllocationStatusUtils.DICT;

export default {
  name: 'BookingList',

  components: {
    Bookings,
    NotificationBell,
    VToolbar,
    VToolbarTitle,
  },

  data() {
    return {
      loading: false,
      bookingList: [],
      bookingDate: TODAY,
      selectedDate: moment(TODAY).format('YYYY-MM-DD'),
    };
  },

  methods: {
    async init() {
      this.loading = true;
      try {
        this.bookingList = await getBookings({
          care_worker_user_name: this.userName,
          start_date_gte: moment(this.bookingDate).startOf('day').toISOString(),
          start_date_lte: moment(this.bookingDate).endOf('day').toISOString(),
          sort: 'start_date',
          is_notified: true,
          booking_allocation_result: [ALLOCATED].toString(),
          booking_status: [NEW, RESCHEDULE_REQUEST].toString(),
        });
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading = false;
    },

    nextDate(numDay) {
      this.bookingDate = moment(this.bookingDate).add(numDay, 'day');
      this.init();
    },

    async changeBookingDate() {
      const date = await this.$showModal({
        component: DateModal,
        props: {
          date: this.selectedDate,
        },
      });

      if (!date) {
        return;
      }

      this.bookingDate = moment(date).format('YYYY-MM-DD');
      this.init();
    },
  },

  created() {
    this.init();
  },
};
</script>
