import axios from 'axios';

import { nullToEmpty, emptyToNull } from 'global-utils';

function transformResponse(summary) {
  return nullToEmpty({
    leaveSummaryId: summary.leave_summary_id,
    isCurrent: summary.is_current,
    financialYear: summary.financial_year,
    workingDaysPerWeek: summary.days_per_week,
    typicalWorkingHrs: summary.typical_working_hrs_per_day,
    annualEntitlement: summary.annual_leave_entitlement,
    annualAccrued: summary.annual_leave_accrued,
    annualCarryForward: summary.annual_leave_carry_forward,
    annualTaken: summary.annual_leave_days_taken,
    medicalAccrued: summary.medical_leave_accrued,
    medicalEntitlement: summary.medical_leave_entitlement,
    medicalCarryForward: summary.medical_leave_carry_forward,
    medicalTaken: summary.medical_leave_days_taken,
    urgentTaken: summary.urgent_leave_days_taken,
    leaveOfAbsenceTaken: summary.leave_of_absence_days_taken,
    createdBy: summary.created_by,
    createdDate: summary.created_date,
    lastModifiedBy: summary.last_modified_by,
    lastModifiedDate: summary.last_modified_date,
  });
}
function transformRequest(summary) {
  return emptyToNull({
    leave_summary: {
      days_per_week: summary.workingDaysPerWeek,
      typical_working_hrs_per_day: summary.typicalWorkingHrs,
      annual_leave_entitlement: summary.annualEntitlement,
      annual_leave_accrued: summary.annualAccrued,
      annual_leave_carry_forward: summary.annualCarryForward,
      annual_leave_days_taken: summary.annualTaken,
      medical_leave_accrued: summary.medicalAccrued,
      medical_leave_entitlement: summary.medicalEntitlement,
      medical_leave_carry_forward: summary.medicalCarryForward,
      medical_leave_days_taken: summary.medicalTaken,
      urgent_leave_days_taken: summary.urgentTaken,
      leave_of_absence_days_taken: summary.leaveOfAbsenceTaken,
    },
  });
}

async function getAll(cwUserName, params) {
  const url = `api/cw/${cwUserName}/leave_summary`;
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function get(cwUserName, summaryId) {
  const url = `api/cw/${cwUserName}/leave_summary/${summaryId}`;
  const response = await axios.get(url);

  return transformResponse(response.data.leave);
}

async function post(cwUserName, summary) {
  const url = `api/cw/${cwUserName}/leave_summary`;
  const request = transformRequest(summary);
  const response = await axios.post(url, request);

  return response.data.leave_summary_id;
}

export { getAll, get, post, transformRequest, transformResponse };
