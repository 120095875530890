<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Profile</v-toolbar-title>
    </v-toolbar>

    <v-container fluid>
      <v-layout justify-space-between>
        <div class="mr-4 text-xs-center">
          <v-avatar :size="120">
            <img :src="profileImageUrl" />
          </v-avatar>
          <div class="title mt-2">
            {{ titleUtils.toLabel(user.profile.title) }}
            {{ user.profile.firstName }}
            {{ user.profile.middleName }}
            {{ user.profile.lastName }}
          </div>
        </div>

        <v-flex class="mt-2">
          <v-layout>
            <v-icon class="mr-2">mail</v-icon>
            {{ user.email }}
          </v-layout>

          <v-layout class="mt-1">
            <v-icon class="mr-2">phone</v-icon>
            {{ user.profile.telephone1 }}
            {{ user.profile.telephone2 }}
          </v-layout>

          <v-layout class="mt-1">
            <v-icon class="mr-2 st-1">cake</v-icon>
            {{ formatDate(user.profile.dob) }}
          </v-layout>
          <v-layout class="mt-1">
            <v-icon class="mr-2">directions</v-icon>
            <span>
              <span> {{ address.street1 }}</span
              ><span v-if="address.street2">, {{ address.street2 }}</span
              ><span>, {{ address.postCode }} </span>
            </span>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-expansion-panel popout>
      <v-expansion-panel-content>
        <template v-slot:header>
          <div class="title">Citizenship Info</div>
        </template>
        <div>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Nationality</v-flex>
            <v-flex class="text-xs-right">
              {{ nationalityUtils.toLabel(careWorker.nationality) }}
              <span v-if="careWorker.isBritishPR">(British PR)</span>
            </v-flex>
          </v-layout>
          <div v-if="!careWorker.isBritishPR">
            <v-layout justify-space-between class="mt-2">
              <v-flex class="font-weight-bold">Passport #</v-flex>
              <v-flex class="text-xs-right">{{ careWorker.passportNumber }}</v-flex>
            </v-layout>
            <v-layout justify-space-between>
              <v-flex class="font-weight-bold">Expiry Date</v-flex>
              <v-flex class="text-xs-right">{{ formatDate(careWorker.passportExpiryDate) }}</v-flex>
            </v-layout>
            <v-layout justify-space-between class="mt-2">
              <v-flex class="font-weight-bold">Visa Status</v-flex>
              <v-flex class="text-xs-right">{{ visaTypeUtils.toLabel(careWorker.visaType) }}</v-flex>
            </v-layout>
            <v-layout justify-space-between>
              <v-flex class="font-weight-bold">Expiry Date</v-flex>
              <v-flex class="text-xs-right">{{ formatDate(careWorker.visaExpiryDate) }}</v-flex>
            </v-layout>
          </div>
        </div>
      </v-expansion-panel-content>
      <v-expansion-panel-content>
        <template v-slot:header>
          <div class="title">Other Info</div>
        </template>
        <div>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Payroll #</v-flex>
            <v-flex class="text-xs-right">{{ careWorker.payrollNumber }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Marital Status</v-flex>
            <v-flex class="text-xs-right">{{ maritalStatusUtils.toLabel(user.profile.maritalStatus) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Sex</v-flex>
            <v-flex class="text-xs-right">{{ genderUtils.toLabel(user.profile.sex) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Religion</v-flex>
            <v-flex class="text-xs-right">{{ religionUtils.toLabel(user.profile.religion) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Ethnicity</v-flex>
            <v-flex class="text-xs-right">{{ ethnicityUtils.toLabel(user.profile.ethnicity) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Language</v-flex>
            <v-flex class="text-xs-right">
              {{
                [user.profile.primaryLanguage, user.profile.secondaryLanguage]
                  .filter((val) => val)
                  .map((val) => languageUtils.toLabel(val))
                  .join(', ')
              }}
            </v-flex>
          </v-layout>
        </div>
      </v-expansion-panel-content>
      <v-expansion-panel-content>
        <template v-slot:header>
          <div class="title">Bank Details</div>
        </template>
        <div>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Account Type</v-flex>
            <v-flex class="text-xs-right">{{ accountTypeUtils.toLabel(bankDetails.accountType) }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Account Name</v-flex>
            <v-flex class="text-xs-right">{{ bankDetails.accountName }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Account Number</v-flex>
            <v-flex class="text-xs-right">{{ bankDetails.accountNumber }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Bank Name</v-flex>
            <v-flex class="text-xs-right">{{ bankDetails.bankName }}</v-flex>
          </v-layout>
          <v-layout justify-space-between>
            <v-flex class="font-weight-bold">Sort Code</v-flex>
            <v-flex class="text-xs-right">{{ bankDetails.sortCode }}</v-flex>
          </v-layout>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import { currentUser } from 'auth-utils';
import { antiBooleanUtils } from 'hcms-const-utils';
import {
  accountTypeUtils,
  ethnicityUtils,
  genderUtils,
  languageUtils,
  maritalStatusUtils,
  nationalityUtils,
  religionUtils,
  titleUtils,
  visaTypeUtils,
} from 'hcms-mc-utils';
import { get as getAttachment } from 'hcms-transforms/attachment';
import { get as getCw } from 'hcms-transforms/cw';
import { get as getBankDetails } from 'hcms-transforms/cw/bank_details';
import { get as getCareWorker } from 'hcms-transforms/cw/staff_details';
import { getAll as getAddresses } from 'hcms-transforms/user/address';
import { VAvatar, VExpansionPanel, VExpansionPanelContent, VToolbar, VToolbarTitle } from 'vuetify/lib';

export default {
  name: 'Profile',
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    VAvatar,
    VToolbar,
    VToolbarTitle,
  },

  constants: {
    accountTypeUtils,
    ethnicityUtils,
    genderUtils,
    antiBooleanUtils,
    languageUtils,
    maritalStatusUtils,
    nationalityUtils,
    religionUtils,
    titleUtils,
    visaTypeUtils,
    userName: currentUser.userName,
  },

  computed: {
    baseEndpoint() {
      return `/api/cw/${this.userName}`;
    },

    isEdited() {
      return !this.equalJSON(this.user, this.originalUser);
    },
  },

  data() {
    return {
      loading: false,
      disableEdit: true,

      user: {
        email: '',
        profile: {},
      },
      address: {},
      careWorker: {},
      bankDetails: {},
      profileImageUrl: '/img/no-photo-man.png',
    };
  },

  methods: {
    async init() {
      this.loading = true;

      try {
        const cwPromise = getCw(this.userName);
        const careWorkerPromise = getCareWorker(this.userName);
        const addressPromise = getAddresses(this.userName, {
          is_current_address: true,
        });
        const bankDetailsPromise = getBankDetails(this.userName);
        await Promise.all([cwPromise, careWorkerPromise, addressPromise, bankDetailsPromise]);
        this.user = await cwPromise;
        this.careWorker = await careWorkerPromise;
        const addresses = await addressPromise;
        this.address = addresses[0];
        this.bankDetails = await bankDetailsPromise;

        if (this.user.profile.profileImageId.length) {
          const profileImageAttachment = await getAttachment(this.user.profile.profileImageId[0]);
          this.profileImageUrl = profileImageAttachment.url;
        }
      } catch (error) {
        console.error(error);
        this.$notify('Could not fetch Profile', 'error');
      }

      this.loading = false;
    },
  },

  async created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.profile-image-edit {
  right: 0;
  bottom: 0;
}
</style>
