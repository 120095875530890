function transformResponse(slot) {
  return {
    id: slot.availability_slot_id,
    day: slot.day_of_week,
    startTime: slot.started_time.split(':').slice(0, 2).join(':'),
    endTime: slot.finished_time.split(':').slice(0, 2).join(':'),
    biweekly: slot.biweekly_mc_id,
  };
}

function transformRequest(slot) {
  return {
    day_of_week: slot.day,
    started_time: slot.startTime,
    finished_time: slot.endTime,
    biweekly_mc_id: slot.biweekly,
  };
}

export { transformResponse, transformRequest };
