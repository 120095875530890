import axios from 'axios';
import { nullToEmpty } from 'global-utils';

function transformResponse(template) {
  return nullToEmpty({
    templateId: template.training_template_id,
    templateName: template.training_type,
    itemsCovered: template.items_covered,
    duration: template.duration,
  });
}

async function getAll(params) {
  const url = 'api/training_template';
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function get(templateId) {
  const url = `api/training_template/${templateId}`;
  const response = await axios.get(url);

  return transformResponse(response.data.training_template);
}

export { transformResponse, getAll, get };
