<template>
  <div v-loading="loading">
    <v-layout class="pa-3" justify-space-between align-center>
      <h5 class="headline">Leave Records</h5>
      <router-link tag="span" :to="{ name: 'New Leave' }">
        <v-btn small color="primary" flat class="ma-0">
          Apply
          <v-icon class="ml-2">add</v-icon>
        </v-btn>
      </router-link>
    </v-layout>

    <v-expansion-panel v-if="leaves.length > 0" popout>
      <v-expansion-panel-content v-for="(leave, i) in leaves" :key="i">
        <template v-slot:header>
          <v-layout row justify-space-between>
            <span>
              {{ formatDate(leave.fromDate) }}
            </span>
            <span class="body-2 mr-2" :class="getStatusClass(leave.leaveStatus)">
              {{ leaveStatusUtils.toLabel(leave.leaveStatus) }}
            </span>
          </v-layout>
        </template>

        <div>
          <v-layout row justify-space-between>
            <div>
              <strong>Date</strong>
            </div>
            <div class="text-xs-right" v-html="getLeaveDate(leave)"></div>
          </v-layout>

          <v-layout row justify-space-between>
            <div>
              <strong>Leave Type</strong>
            </div>
            <div>
              {{ formatDate(leave.createdDate) }}
            </div>
          </v-layout>

          <v-layout row justify-space-between>
            <div>
              <strong>Applied On</strong>
            </div>
            <div>
              {{ leaveTypeUtils.toLabel(leave.leaveType) }}
            </div>
          </v-layout>

          <v-layout row justify-space-between>
            <div>
              <strong>Reason</strong>
            </div>
            <div>
              {{ leave.reason }}
            </div>
          </v-layout>

          <v-layout v-if="leave.leaveStatus === PENDING" row justify-end>
            <v-btn class="ma-0" flat color="error" small @click="withdrawLeave(leave)">
              <v-icon class="mr-2">close</v-icon>
              Withdraw
            </v-btn>
          </v-layout>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-container fluid v-else>
      <v-layout class="grey--text" align-center justify-center> No Leave Records </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import ConfirmationModal from '@/views/Common/ConfirmationModal.vue';

import { getAll as getLeaves, put as putLeave } from 'hcms-transforms/cw/leave';

import { leaveTypeUtils, leaveStatusUtils } from 'hcms-mc-utils';

import { VExpansionPanel, VExpansionPanelContent } from 'vuetify/lib';

const getHalf = (date) => (moment(date).hours() >= 12 ? 'Second Half' : 'First Half');

const { PENDING, APPROVED, REJECTED, WITHDRAWN } = leaveStatusUtils.DICT;

export default {
  name: 'LeaveHistory',

  components: {
    VExpansionPanel,
    VExpansionPanelContent,
  },

  constants: {
    leaveTypeUtils,
    leaveStatusUtils,
    PENDING,
  },

  data() {
    return {
      loading: false,
      leaves: [],
    };
  },

  methods: {
    getLeaveDate(leave) {
      const start = moment(leave.fromDate);
      const end = moment(leave.toDate);

      if (start.format('DDMMYYYY') === end.format('DDMMYYYY')) {
        const startHalf = getHalf(start);
        const endHalf = getHalf(end);

        if (startHalf === endHalf) {
          return `${this.formatDate(start)}, ${startHalf}`;
        }

        return `${this.formatDate(start)}, ${startHalf} - ${endHalf}`;
      }
      return `${this.formatDate(start)}, ${getHalf(start)}<br>${this.formatDate(end)}, ${getHalf(end)}`;
    },
    async init() {
      this.loading = true;
      try {
        this.leaves = await getLeaves(this.userName, {
          sort: '-from_date',
        });
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading = false;
    },

    async withdrawLeave(leave) {
      const response = await this.$showModal({
        component: ConfirmationModal,
        props: {
          description: 'Are you sure you want to withdraw the leave?',
          primaryAction: {
            title: 'Withdraw',
            color: 'error',
          },
        },
      });
      if (!response) {
        return;
      }
      try {
        await putLeave(this.userName, leave.leaveId, {
          ...leave,
          leaveStatus: WITHDRAWN,
          adminRemark: 'Withdrawn by user',
        });
        this.$notify('The Leave has been withdrawn', 'success');

        this.init();
      } catch (err) {
        console.error(err);
        this.$notify("Couldn't withdraw leave, please contact admin.", 'error');
      }
    },

    getStatusClass(status) {
      if (status === APPROVED) {
        return 'success--text';
      }

      if (status === REJECTED) {
        return 'error--text';
      }

      if (status === PENDING) {
        return 'warning--text';
      }

      return 'grey--text';
    },
  },

  created() {
    this.init();
  },
};
</script>
