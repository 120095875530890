import { currentUser } from 'auth-utils';
import { AUTH as AUTH_PATH } from 'hcms-constants/APP_PATHS';
import { roleUtils } from 'hcms-const-utils';

function checkAuthentication() {
  if (!currentUser.isAuthenticated) {
    window.location.replace(`${AUTH_PATH}?redirect_url=${window.location.href}`);
    return;
  }

  if (currentUser.role !== roleUtils.DICT.CW) {
    window.location.replace(`${AUTH_PATH}`);
  }
}

checkAuthentication();
