import axios from 'axios';
import moment from 'moment';

import { nullToEmpty } from 'global-utils';

function transformResponse(leave) {
  return nullToEmpty({
    leaveId: leave.leave_id,
    leaveType: leave.leave_type_mc_id,
    leaveStatus: leave.leave_status_mc_id,
    fromDate: moment(leave.from_date),
    toDate: moment(leave.to_date),
    leaveDocId: leave.leave_doc_id,
    reason: leave.note,
    adminRemark: leave.admin_remark,
    createdDate: moment(leave.created_date),
    leaveBalance: leave.leave_balance,

    modifiedBy: leave.last_modified_by,
    modifiedDate: leave.last_modified_date
      ? moment(leave.last_modified_date)
      : '',
  });
}
function transformRequest(leave) {
  return {
    leave: {
      to_date: leave.toDate,
      from_date: leave.fromDate,
      admin_remark: leave.adminRemark,
      leave_status_mc_id: leave.leaveStatus,
      leave_type_mc_id: leave.leaveType,
      leave_doc_id: leave.leaveDocId,
      note: leave.reason,
      attachment_ids: leave.attachments,
    },
  };
}

async function getAll(cwUserName, params) {
  const url = `api/cw/${cwUserName}/leave`;
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

async function get(cwUserName, leaveId) {
  const url = `api/cw/${cwUserName}/leave/${leaveId}`;
  const response = await axios.get(url);

  return transformResponse(response.data.leave);
}

async function post(cwUserName, leaveData) {
  const url = `api/cw/${cwUserName}/leave`;
  const request = transformRequest(leaveData);
  const response = await axios.post(url, request);

  return response.data.leave_id;
}

async function put(cwUserName, leaveId, leaveData) {
  const url = `api/cw/${cwUserName}/leave/${leaveId}`;
  const request = transformRequest(leaveData);
  await axios.put(url, request);
}

export { getAll, get, post, put, transformRequest, transformResponse };
