<template>
  <v-app class="app-container" @click="clickLog">
    <v-content :class="showNav ? 'has-bottom-toolbar' : ''">
      <router-view></router-view>
    </v-content>
    <v-bottom-nav v-if="showNav" class="v-bottom-nav--sticky" color="primary" dark absolute :value="true" app>
      <v-btn flat to="/overview">
        <span>Overview</span>
        <v-icon>assignment</v-icon>
      </v-btn>

      <v-btn flat to="/bookings">
        <span>Bookings</span>
        <v-icon>map</v-icon>
      </v-btn>

      <v-btn flat to="/leave">
        <span>Leave</span>
        <v-icon>work_off</v-icon>
      </v-btn>

      <v-btn flat to="/more">
        <span>More</span>
        <v-icon>settings</v-icon>
      </v-btn>
    </v-bottom-nav>
  </v-app>
</template>

<script>
import ConfirmationModal from '@/views/Common/ConfirmationModal.vue';
import { post as logActivity } from 'hcms-transforms/log_activity';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { HOUR, MINUTE } from 'tz-utils';

const FIVE_MINUTES = 5 * MINUTE;
const LOGOUT_REMINDER = HOUR - FIVE_MINUTES;

export default {
  name: 'app',
  data() {
    return {
      lastActivity: moment(),
    };
  },
  computed: {
    enableAutoLogout() {
      return process.env.VUE_APP_DEBUG !== 'true' && this.$store.state.getters.isLiveMode;
    },
  },
  methods: {
    async refreshLogin() {
      try {
        await logActivity();
        this.lastActivity = moment();
        this.resetLogoutReminder();
      } catch (error) {
        console.error(error);
      }
    },
    async showLogoutReminder() {
      const response = await this.$showModal({
        component: ConfirmationModal,
        props: {
          title: 'Session Timeout',
          description: `You will be logged out at ${this.formatTime(
            moment(this.lastActivity).add(60, 'min'),
          )} due to inactivity.`,
          primaryAction: {
            title: 'Continue session',
            color: 'success',
          },
        },
      });

      if (response) {
        await this.sleep(0);
        this.refreshLogin();
      }
    },
    resetLogoutReminder() {
      if (this.enableAutoLogout) {
        window.clearTimeout(this.timeoutId);
        this.timeoutId = window.setTimeout(() => {
          this.showLogoutReminder();
        }, LOGOUT_REMINDER);
      }
    },
  },
  created() {
    this.clickLog = debounce(
      () => {
        this.refreshLogin();
      },
      FIVE_MINUTES,
      { leading: true, trailing: false },
    );
    this.resetLogoutReminder();
  },
};
</script>
<style lang="scss">
.app-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
