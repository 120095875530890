<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>assignment</v-icon>
      <v-toolbar-title>Overview</v-toolbar-title>
      <v-spacer></v-spacer>
      <notification-bell></notification-bell>
    </v-toolbar>

    <div v-if="training">
      <v-layout class="pa-3">
        <v-icon class="mr-2 error--text">info</v-icon>
        You have a Training {{ isTrainingToday ? 'Today' : 'Tomorrow' }}, {{ getHalf(training.start) }}.
        <br />
        Please arrive by {{ training.arrivalTime }} at
        {{ training.trainingCentre }}
      </v-layout>
      <v-divider />
    </div>

    <v-layout align-center class="pa-3">
      <h5 class="title">Upcoming Bookings</h5>
    </v-layout>
    <bookings v-loading="loading.upcomingBookings" v-model="upcomingBookings" />

    <v-layout align-center class="pa-3">
      <h5 class="title">Past Bookings</h5>
    </v-layout>
    <bookings v-loading="loading.completedBookings" v-model="completedBookings" />
  </div>
</template>

<script>
import { bookingAllocationStatusUtils, bookingStatusUtils } from 'hcms-mc-utils';
import { getAll as getBookings } from 'hcms-transforms/booking';
import { getAll as getScheduledTrainingSlots } from 'hcms-transforms/scheduled_training_slot';
import { get as getTrainingTemplate } from 'hcms-transforms/training_template';
import moment from 'moment';

import { VToolbar, VToolbarTitle } from 'vuetify/lib';

import { currentUser } from 'auth-utils';
import Bookings from 'components/Bookings.vue';
import NotificationBell from 'components/NotificationBell.vue';

const { NEW, RESCHEDULE_REQUEST } = bookingStatusUtils.DICT;
const { ALLOCATED } = bookingAllocationStatusUtils.DICT;

export default {
  name: 'Overview',

  components: {
    Bookings,
    NotificationBell,
    VToolbar,
    VToolbarTitle,
  },

  data() {
    return {
      loading: {
        upcomingBookings: false,
        completedBookings: false,
        training: false,
      },
      upcomingBookings: [],
      completedBookings: [],
      training: '',
      trainingTemplate: '',
    };
  },

  computed: {
    NOW() {
      return moment();
    },
    isTrainingToday() {
      return this.training.start.diff(this.NOW, 'day') < 1;
    },
    baseParams() {
      return {
        care_worker_user_name: this.userName,
        limit: 3,
        is_notified: true,
        booking_allocation_result: [ALLOCATED].toString(),
        booking_status: [NEW, RESCHEDULE_REQUEST].toString(),
      };
    },
  },

  methods: {
    async getUpcomingBookings() {
      this.loading.upcomingBookings = true;

      try {
        this.upcomingBookings = await getBookings({
          ...this.baseParams,
          sort: 'start_date',
          start_date_gte: moment(this.NOW).toISOString(),
        });
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading.upcomingBookings = false;
    },

    async getCompletedBookings() {
      this.loading.completedBookings = true;
      try {
        this.completedBookings = await getBookings({
          ...this.baseParams,
          sort: '-start_date',
          start_date_lte: moment(this.NOW).toISOString(),
        });
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading.completedBookings = false;
    },

    async getUpcomingTraining() {
      const trainingParams = {
        careworker: currentUser.userName,
        start_gte: moment(this.NOW).startOf('day').toISOString(),
        start_lte: moment(this.NOW).endOf('day').add(1, 'day').toISOString(),
        sort: 'start',
        limit: 1,
      };

      this.loading.training = true;
      try {
        const trainings = await getScheduledTrainingSlots(trainingParams);
        if (trainings.length > 0) {
          this.training = trainings[0];
          this.trainingTemplate = await getTrainingTemplate(this.training.trainingType);
        }
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading.training = false;
    },

    init() {
      this.getUpcomingBookings();
      this.getCompletedBookings();
      this.getUpcomingTraining();
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.report-btn {
  margin-right: -12px;
}
</style>
