<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Availability Request</v-toolbar-title>
    </v-toolbar>
    <div class="pa-3">
      <v-layout class="mb-3">
        <v-flex xs6>
          <h5 class="subheading">
            <strong>Contract Hours</strong>
          </h5>
          <h4>{{ contractHours }} Hours</h4>
        </v-flex>
        <v-flex xs6>
          <h5 class="subheading">
            <strong>Specified Availability</strong>
          </h5>
          <h4>{{ editedDuration }} Hours</h4>
        </v-flex>
      </v-layout>
      <resource-timeline v-bind="resourceTimelineProps" @eventClick="viewSlotDetails" />
      <div class="text-xs-right mt-3">
        <span class="font-weight-bold">Effective From:</span>
        {{ formatDate(pendingAvailability.effectiveFrom) }}
      </div>
    </div>
    <v-fab-transition>
      <v-btn absolute dark fab bottom right color="red" @click="withdrawRequest">
        <v-icon>undo</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { availabilityStatusUtils, biweeklyUtils, employmentStatusUtils } from 'hcms-mc-utils';
import { getAll as getAvailabilities, put as putAvailability } from 'hcms-transforms/cw/availability';
import { getAll as getContracts } from 'hcms-transforms/cw/employment';
import differenceBy from 'lodash.differenceby';
import intersectionBy from 'lodash.intersectionby';
import moment from 'moment';
import { ResourceTimeline } from 'vue-resource-timeline';
import { VFabTransition, VToolbar, VToolbarTitle } from 'vuetify/lib';
import ConfirmAvailability from './ConfirmAvailability.vue';
import SlotDetails from './SlotDetails.vue';

const toSlotIndentity = (slot) => `${slot.day} ${slot.startTime} ${slot.endTime} ${slot.biweekly}`;

const CURRENT_CONTRACT = employmentStatusUtils.DICT.CURRENT;

const { ODD_WEEK, EVEN_WEEK } = biweeklyUtils.DICT;

const { WITHDRAWN } = availabilityStatusUtils.DICT;
const currentQuery = {
  status: availabilityStatusUtils.DICT.CURRENT,
};
const pendingQuery = {
  status: availabilityStatusUtils.DICT.PENDING,
};

const empty = {
  slots: [],
};

export default {
  name: 'ChangeAvailability',

  components: {
    ResourceTimeline,
    VToolbar,
    VToolbarTitle,
    VFabTransition,
  },

  data() {
    return {
      loading: false,
      currentAvailability: this.cloneJSON(empty),
      pendingAvailability: this.cloneJSON(empty),
      currentContract: '',
    };
  },

  computed: {
    contractHours() {
      if (!this.currentContract) {
        return 0;
      }
      return this.currentContract.availabilityHr;
    },
    editedDuration() {
      const slotToDuration = (slot) =>
        Number(moment(slot.endTime, 'HH:mm').diff(moment(slot.startTime, 'HH:mm'), 'minutes'));
      const durationInMinutes = this.pendingAvailability.slots.reduce((acc, slot) => acc + slotToDuration(slot), 0);
      return durationInMinutes / 60;
    },
    unchangedSlots() {
      return intersectionBy(this.pendingAvailability.slots, this.currentAvailability.slots, toSlotIndentity);
    },
    beforeSlots() {
      return differenceBy(this.currentAvailability.slots, this.unchangedSlots, toSlotIndentity);
    },
    afterSlots() {
      return differenceBy(this.pendingAvailability.slots, this.unchangedSlots, toSlotIndentity);
    },
    changedSlots() {
      return [...this.afterSlots, ...this.beforeSlots];
    },
    resourceTimelineProps() {
      const getBiweeklyClass = (biweekly) => {
        if (biweekly === ODD_WEEK) {
          return 'rt__event--odd';
        }
        if (biweekly === EVEN_WEEK) {
          return 'rt__event--even';
        }
        return '';
      };
      const getResourceIdSuffixer =
        (suffix = '') =>
        (slot) => ({
          origSlot: slot,
          resourceId: `${slot.day}${suffix}`,
          start: slot.startTime,
          end: slot.endTime,
          title: `${slot.startTime} - ${slot.endTime}`,
          class: getBiweeklyClass(slot.biweekly),
        });
      const weekDays = moment.weekdaysShort(true);

      const unchangedClass = 'rt__resource--success-dull rt__resource--active';
      const beforeClass = 'rt__resource--danger';
      const afterClass = 'rt__resource--success rt__resource--active';

      const unchangedSuffix = '_unchanged';
      const beforeSuffix = '_before';
      const afterSuffix = '_after';
      const unchangedSuffixer = getResourceIdSuffixer(unchangedSuffix);
      const beforeSuffixer = getResourceIdSuffixer(beforeSuffix);
      const afterSuffixer = getResourceIdSuffixer(afterSuffix);

      const events = [];
      const resources = [];

      weekDays.forEach((day, index) => {
        // * Check if a day has changes
        const isChange = this.changedSlots.find((slot) => slot.day === index);

        // * If a day doesn't have changes, show it as unchanged, otherwise show before & after
        if (!isChange) {
          resources.push({
            id: `${index}${unchangedSuffix}`,
            title: day,
            day: index,
            class: unchangedClass,
          });
          const dayEvents = this.pendingAvailability.slots.filter((slot) => slot.day === index).map(unchangedSuffixer);
          events.push(...dayEvents);
        } else {
          resources.push(
            {
              id: `${index}${beforeSuffix}`,
              title: day,
              day: index,
              class: beforeClass,
            },
            {
              id: `${index}${afterSuffix}`,
              title: '',
              day: index,
              class: afterClass,
            },
          );
          const dayBeforeEvents = this.currentAvailability.slots
            .filter((slot) => slot.day === index)
            .map(beforeSuffixer);
          const dayAfterEvents = this.pendingAvailability.slots.filter((slot) => slot.day === index).map(afterSuffixer);

          events.push(...dayBeforeEvents, ...dayAfterEvents);
        }
      });

      return {
        resources,
        events,
        class: 'rt--hide-add',
        showEmptyResource: true,
      };
    },
  },

  methods: {
    async getCurrentContract() {
      const params = {
        employment_status: CURRENT_CONTRACT,
      };

      const contracts = await getContracts(this.userName, params);

      this.currentContract = contracts[0];
    },
    async getAvailabilities() {
      this.loading = true;
      try {
        const currAvails = await getAvailabilities(this.userName, currentQuery);
        const pendingAvails = await getAvailabilities(this.userName, pendingQuery);

        this.currentAvailability = currAvails.length ? currAvails[0] : this.cloneJSON(empty);
        this.pendingAvailability = pendingAvails.length ? pendingAvails[0] : this.cloneJSON(empty);
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading = false;
    },

    viewSlotDetails(event) {
      const slot = event.origSlot;
      this.$showModal({
        component: SlotDetails,
        props: {
          prevSlot: slot,
          disabled: true,
        },
      });
    },

    async withdrawRequest() {
      try {
        const confirmation = await this.$showModal({
          component: ConfirmAvailability,
          props: {
            title: 'Withdraw Request',
            color: 'error',
          },
        });

        if (!confirmation) {
          return;
        }
        await putAvailability(this.userName, this.pendingAvailability.availabilityId, {
          ...this.pendingAvailability,
          status: WITHDRAWN,
        });
        this.$notify('The request has been withdrawn', 'success');
        this.goBack();
      } catch (err) {
        this.$notify('Could not withdraw request, please contact admin', 'danger');
      }
    },
  },

  created() {
    this.getCurrentContract();
    this.getAvailabilities();
  },
};
</script>
