<template>
  <router-link tag="span" :to="{ name: 'Notifications' }">
    <v-badge class="notification-bell" color="red">
      <template v-slot:badge v-if="totalNotifications">
        {{ totalNotifications }}
      </template>

      <v-btn icon>
        <v-icon>notifications</v-icon>
      </v-btn>
    </v-badge>
  </router-link>
</template>

<script>
import axios from 'axios';

import { VBadge } from 'vuetify/lib';

import { notificationTypeUtils, notificationStatusUtils } from 'hcms-mc-utils';
import { MINUTE } from 'tz-utils';

export default {
  name: 'NotificationBell',
  components: {
    VBadge,
  },

  constants: {
    notificationTypes: [
      notificationTypeUtils.DICT.NOTIFICATION,
      notificationTypeUtils.DICT.REMINDER,
      notificationTypeUtils.DICT.ALERT,
      notificationTypeUtils.DICT.REQUEST,
    ],
  },

  data() {
    return {
      loading: false,
      totalNotifications: 0,
    };
  },

  computed: {
    queryObject() {
      const query = {
        seen: false,
        limit: 1,
        notification_type: this.notificationTypes.join(','),
        notification_status: [notificationStatusUtils.DICT.ELEVATED, notificationStatusUtils.DICT.NEW].join(','),
      };
      return query;
    },

    baseEndpoint() {
      return this.hasVmAccess ? 'api/notification_without_bookings' : '/api/notification';
    },
  },

  methods: {
    async init() {
      this.loading = true;
      const url = this.baseEndpoint;
      try {
        const response = await axios.get(url, {
          params: this.queryObject,
        });
        this.totalNotifications = response.data.count;
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading = false;
    },

    raiseNotificationAlert() {
      this.notificationTimer = window.setTimeout(() => {
        if (this.totalNotifications) {
          this.$notify('You have pending notifications', 'error');
          this.raiseNotificationAlert();
        }
      }, 5 * MINUTE);
    },
  },

  created() {
    this.init();
    this.raiseNotificationAlert();
  },

  destroyed() {
    window.clearTimeout(this.notificationTimer);
  },
};
</script>

<style lang="scss" scoped>
.notification-bell {
  margin-right: -12px;
}
</style>
