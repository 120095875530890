<template>
  <div v-loading="loading.init">
    <v-container fluid>
      <h5 class="headline mb-3">Leave Entitlement</h5>

      <v-layout row wrap v-if="leaveSummary">
        <table class="leave-summary__table">
          <thead>
            <tr class="border">
              <th></th>
              <th>Annual</th>
              <th>Medical</th>
              <th>Unpaid</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border text-muted">
              <td>Total Entitled / Year</td>
              <td>{{ leaveSummary.annualEntitlement }}</td>
              <td>{{ leaveSummary.medicalEntitlement }}</td>
              <td class="text-muted">-</td>
            </tr>
            <tr>
              <td>Accrued</td>
              <td>{{ leaveSummary.annualAccrued }}</td>
              <td>{{ leaveSummary.medicalAccrued }}</td>
              <td class="text-muted">-</td>
            </tr>
            <tr v-if="leaveSummary.annualCarryForward || leaveSummary.medicalCarryForward">
              <td>Carry Forward</td>
              <td>{{ leaveSummary.annualCarryForward }}</td>
              <td>{{ leaveSummary.medicalCarryForward }}</td>
              <td class="text-muted">-</td>
            </tr>
            <tr>
              <td>Non-Urgent Taken</td>
              <td>{{ leaveSummary.annualTaken }}</td>
              <td class="text-muted">-</td>
              <td>{{ leaveSummary.leaveOfAbsenceTaken }}</td>
            </tr>
            <tr>
              <td>Urgent Taken</td>
              <td>{{ leaveSummary.urgentTaken }}</td>
              <td>{{ leaveSummary.medicalTaken }}</td>
              <td class="text-muted">-</td>
            </tr>
            <tr class="border">
              <td>Balance</td>
              <td>{{ leaveSummary.annualBalance }}</td>
              <td>{{ leaveSummary.medicalBalance }}</td>
              <td class="text-muted">-</td>
            </tr>
            <tr>
              <td>
                <span class="bold">£ / Day Off:</span>
                <span>{{ leaveSummary.dayCashEquivalent }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </v-layout>
      <v-flex v-else class="pa-2 grey--text text-xs-center"> Leave Entitlement is not entered </v-flex>
    </v-container>
  </div>
</template>

<script>
import { getAll as getLeaveSummaries } from 'hcms-transforms/cw/leave_summary';

export default {
  name: 'LeaveSummary',

  data() {
    return {
      loading: {
        init: false,
      },

      leaveSummary: null,
    };
  },

  methods: {
    async init() {
      this.loading.init = true;
      try {
        const leaveSummaries = await getLeaveSummaries(this.userName, {
          is_current: true,
        });
        this.leaveSummary = leaveSummaries[0];
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading.init = false;
    },
  },

  created() {
    this.init();
  },
};
</script>
<style lang="scss">
.leave-summary {
  &__table {
    width: 100%;
    text-align: right;
    border-collapse: collapse;
    margin-bottom: -2px;

    tr.border {
      border-bottom: 1px solid #ddd;
    }

    th {
      vertical-align: bottom;
    }

    td,
    th {
      padding: 2px;

      &:first-child {
        text-align: left;
        font-weight: 600;
      }
    }
  }
}
</style>
