<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Contacts</v-toolbar-title>
    </v-toolbar>

    <v-expansion-panel v-if="contacts.length > 0" popout>
      <v-expansion-panel-content v-for="contact in contacts" :key="contact.contactId">
        <template v-slot:header>
          <v-layout>
            <v-icon class="mr-2" :class="{ 'error--text': contact.isEmergencyContact }">
              {{ contact.isEmergencyContact ? 'settings_input_antenna' : 'person' }}
            </v-icon>
            {{ contact.contactName }}
            ({{ contactTypeUtils.toLabel(contact.contactType) }})
          </v-layout>
        </template>
        <div>
          <v-layout class="mb-2" align-center v-if="contact.telephone1">
            <v-btn icon class="ma-0 mr-2" target="_blank" :href="`tel:${contact.telephone1}`">
              <v-icon>phone</v-icon>
            </v-btn>
            {{ contact.telephone1 }}
          </v-layout>
          <v-layout class="mb-2" align-center v-if="contact.telephone2">
            <v-btn icon class="ma-0 mr-2" target="_blank" :href="`tel:${contact.telephone2}`">
              <v-icon>phone</v-icon>
            </v-btn>
            {{ contact.telephone2 }}
          </v-layout>
          <v-layout align-center>
            <v-btn icon class="ma-0 mr-2" target="_blank" :href="`https://www.google.com/maps?q=${contact.postCode}`">
              <v-icon>directions</v-icon>
            </v-btn>
            {{ contact.street1 }}<span v-if="contact.street2">, {{ contact.street2 }}</span
            >, {{ contact.postCode }}
          </v-layout>
          <v-layout class="text-xs-right" align-center justify-end v-if="contact.isEmergencyContact">
            <v-icon class="body-1 error--text ma-0 mr-2">settings_input_antenna</v-icon>
            <span class="body-1 error--text"> Emergency Contact </span>
          </v-layout>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-container fluid v-else>
      <v-layout class="grey--text" align-center justify-center> No Contacts </v-layout>
    </v-container>
  </div>
</template>

<script>
import { contactTypeUtils } from 'hcms-mc-utils';
import { get as getContact } from 'hcms-transforms/contact';
import { get as getCw } from 'hcms-transforms/cw';
import { VExpansionPanel, VExpansionPanelContent, VToolbar, VToolbarTitle } from 'vuetify/lib';

export default {
  name: 'Contacts',

  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    VToolbar,
    VToolbarTitle,
  },

  constants: {
    contactTypeUtils,
  },

  data() {
    return {
      loading: false,
      contacts: [],
    };
  },

  methods: {
    async init() {
      try {
        const user = await getCw(this.userName);
        const contactIds = user.profile.contacts;
        const contactPromises = contactIds.map(getContact);
        this.contacts = await Promise.all(contactPromises);
      } catch (error) {
        this.$notify('Could not fetch contacts', 'error');
      }
    },
  },

  async created() {
    this.init();
  },
};
</script>
