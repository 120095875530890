<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Trainings</v-toolbar-title>
    </v-toolbar>

    <v-expansion-panel v-if="trainings.length > 0" :value="expandIndex" popout>
      <v-expansion-panel-content v-for="training in trainings" :key="training.trainingId">
        <template v-slot:header>
          <v-layout row>
            <v-flex>
              {{ training.trainingCentre }}
            </v-flex>
            <v-flex class="text-xs-right">
              {{ formatDate(training.start) }}
            </v-flex>
          </v-layout>
        </template>

        <div>
          <v-layout row class="pb-2">
            <v-flex>
              <strong>Date</strong>
            </v-flex>
            <v-flex class="text-xs-right">
              {{ formatHalfRange(training.start, training.end) }}
            </v-flex>
          </v-layout>
          <v-layout row class="pb-2">
            <v-flex>
              <strong>Arrival Time</strong>
            </v-flex>
            <v-flex class="text-xs-right">
              {{ training.arrivalTime }}
            </v-flex>
          </v-layout>
          <v-layout row class="pb-2">
            <v-flex>
              <strong>Type</strong>
            </v-flex>
            <v-flex class="text-xs-right">
              {{ trainingTemplateUtils.toLabel(training.trainingType) }}
            </v-flex>
          </v-layout>

          <v-layout row class="pb-2" v-if="training.trainingCentre">
            <v-flex>
              <strong>Centre</strong>
            </v-flex>
            <v-flex class="text-xs-right">
              {{ training.trainingCentre }}
            </v-flex>
          </v-layout>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-container fluid v-else>
      <v-layout class="grey--text" align-center justify-center> No Trainings </v-layout>
    </v-container>
  </div>
</template>

<script>
import { Constant } from 'hcms-const-utils';
import { getAll as getTrainings } from 'hcms-transforms/scheduled_training_slot';
import { getAll as getTrainingTemplates } from 'hcms-transforms/training_template';
import { VExpansionPanel, VExpansionPanelContent, VToolbar, VToolbarTitle } from 'vuetify/lib';

export default {
  name: 'Trainings',

  props: {
    trainingId: {
      required: false,
      type: Number,
    },
  },

  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    VToolbar,
    VToolbarTitle,
  },

  data() {
    return {
      loading: false,
      trainings: [],
      trainingTemplateUtils: new Constant([]),
    };
  },

  computed: {
    expandIndex() {
      if (!this.trainingId || this.trainings.legth === 0) {
        return null;
      }
      return this.trainings.findIndex((training) => training.trainingId === this.trainingId);
    },
  },

  methods: {
    async updateTrainings() {
      try {
        this.trainings = await getTrainings({
          careworker: this.userName,
        });
      } catch (error) {
        this.$notify(error, 'error');
      }
    },

    async getTrainingTemplates() {
      const trainingTemplates = await getTrainingTemplates();
      const trainingTemplateOptions = trainingTemplates.map((template) => ({
        value: template.templateId,
        label: template.templateName,
      }));
      this.trainingTemplateUtils = new Constant(trainingTemplateOptions);
    },

    isCompleted(status) {
      if (status !== 'Scheduled') {
        return true;
      }
      return false;
    },
  },

  async created() {
    this.loading = true;
    await this.getTrainingTemplates();
    await this.updateTrainings();
    this.loading = false;
  },
};
</script>
