import moment from 'moment';
import axios from 'axios';
import { nullToEmpty, emptyToNull, cloneJSON } from 'global-utils';

function transformResponse(details) {
  return nullToEmpty({
    payrollNumber: details.payroll_number,

    nationality: details.nationality_mc_id,
    workPermitRequired: !details.is_permanent_uk_resident,

    passportNumber: details.passport_number,
    passportExpiryDate: details.passport_expiry_date
      ? moment(details.passport_expiry_date)
      : null,
    passportDocId: details.passport_doc_id,

    visaType: details.visa_status_mc_id,
    visaExpiryDate: details.visa_expiry_date
      ? moment(details.visa_expiry_date)
      : null,
    visaDocId: details.visa_doc_id,

    proofDocId: details.residency_proof_doc_id,

    isDbsRequired: Boolean(details.is_dbs_required),
    dbsType: details.dbs_type_mc_id,
    dbsExpiryDate: details.dbs_expiry_date
      ? moment(details.dbs_expiry_date)
      : null,
    dbsDocId: details.dbs_doc_id,
    dbsExemptionDocId: details.dbs_exemption_doc_id,

    createdDate: moment(details.last_modified_date),
    createdBy: details.last_modified_by,
  });
}

function transformRequest(staff) {
  const details = cloneJSON(staff);

  if (details.workPermitRequired) {
    details.proofDocId = [];
  } else {
    details.passportNumber = '';
    details.passportExpiryDate = '';
    details.visaType = '';
    details.visaExpiryDate = '';
    details.passportDocId = [];
    details.visaDocId = [];
  }

  if (details.isDbsRequired) {
    details.dbsExemptionDocId = [];
  } else {
    details.dbsType = '';
    details.dbsExpiryDate = '';
    details.dbsDocId = [];
  }

  return emptyToNull({
    payroll_number: details.payrollNumber,

    nationality_mc_id: details.nationality,
    is_permanent_uk_resident: !details.workPermitRequired,

    passport_number: details.passportNumber,
    passport_expiry_date: details.passportExpiryDate,
    passport_doc_id: details.passportDocId,

    visa_status_mc_id: details.visaType,
    visa_expiry_date: details.visaExpiryDate,
    visa_doc_id: details.visaDocId,

    residency_proof_doc_id: details.proofDocId,

    is_dbs_required: Boolean(details.isDbsRequired),
    dbs_type_mc_id: details.dbsType,
    dbs_expiry_date: details.dbsExpiryDate,
    dbs_doc_id: details.dbsDocId,
    dbs_exemption_doc_id: details.dbsExemptionDocId,
  });
}

async function get(userName) {
  const url = `api/cw/${userName}`;
  const response = await axios.get(url);

  return transformResponse(response.data.user.careworker);
}

async function put(userName, staffDetails) {
  const url = `api/cw/${userName}`;
  const details = transformRequest(staffDetails);
  const request = {
    user: {
      careworker: details,
    },
  };
  await axios.put(url, request);
}

export { get, put, transformResponse, transformRequest };
