<template>
  <v-card class="time-modal">
    <v-time-picker :allowedMinutes="allowedMinutes" :min="min" :max="max" v-model="selectedTime">
      <v-spacer></v-spacer>
      <v-btn class="ma-0" flat color="primary" @click="selectTime">Select</v-btn>
    </v-time-picker>
  </v-card>
</template>
<script>
import { VCard, VTimePicker } from 'vuetify/lib';

export default {
  name: 'TimeModal',
  components: {
    VCard,
    VTimePicker,
  },
  props: ['time', 'allowedMinutes', 'min', 'max'],
  data() {
    return {
      selectedTime: '',
    };
  },

  methods: {
    selectTime() {
      this.$emit('close', this.selectedTime);
    },
    dismiss() {
      this.$emit('dismiss');
    },
  },

  created() {
    this.selectedTime = this.time;
  },
};
</script>
<style lang="scss" scoped>
.time-modal {
  width: 290px;
}
</style>
