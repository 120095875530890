<template>
  <div>
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Roster Notification</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-layout align-center class="pa-3">
      <h5 class="headline">Allocated Bookings</h5>
    </v-layout>
    <bookings v-loading="loading.init" v-model="bookings" />
  </div>
</template>

<script>
import Bookings from 'components/Bookings.vue';
import { bookingAllocationStatusUtils, bookingStatusUtils } from 'hcms-mc-utils';
import { getAll as getBookings } from 'hcms-transforms/booking';
import { VToolbar, VToolbarTitle } from 'vuetify/lib';

const { RESCHEDULE_REQUEST, NEW } = bookingStatusUtils.DICT;
const { ALLOCATED } = bookingAllocationStatusUtils.DICT;

export default {
  name: 'Overview',

  props: {
    engineRunId: {
      required: true,
      type: Number,
    },
  },

  components: {
    Bookings,
    VToolbar,
    VToolbarTitle,
  },

  data() {
    return {
      loading: {
        init: false,
      },
      bookings: [],
    };
  },

  computed: {
    params() {
      return {
        care_worker_user_name: this.userName,
        booking_allocation_result: [ALLOCATED].toString(),
        booking_status: [NEW, RESCHEDULE_REQUEST].toString(),
        is_notified: true,
        engine_run_id: this.engineRunId,
        sort: 'start_date',
      };
    },
  },

  methods: {
    async init() {
      this.loading.init = true;

      try {
        this.bookings = await getBookings(this.params);
      } catch (err) {
        this.$notify(err, 'error');
      }

      this.loading.init = false;
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.report-btn {
  margin-right: -12px;
}
</style>
