import axios from 'axios';
import {
  preferenceTypeUtils,
  preferenceIncorporationTypeUtils,
} from 'hcms-mc-utils';

const SOFT = preferenceIncorporationTypeUtils.toValue('Soft');
const HARD = preferenceIncorporationTypeUtils.toValue('Hard');

const PREFERRED_BOOKING_TYPE_PREF = preferenceTypeUtils.toValue(
  'Preferred Booking Type',
);
const EXCLUDED_BOOKING_TYPE_PREF = preferenceTypeUtils.toValue(
  'Excluded Booking Type',
);

function transformResponse(preferenceData) {
  const getPreference = (preferenceType) =>
    preferenceData.find(
      (pref) => pref.preference_type_mc_id === preferenceType,
    );

  const preferences = preferenceTypeUtils.OPTIONS.filter(
    (opt) => opt.meta.forCw && !opt.meta.special,
  ).map((opt) => {
    const pref = getPreference(opt.value);
    return {
      preferenceLabel: opt.label,
      preferenceType: opt.value,
      preferenceValue: Boolean(pref),
      incorporationType: pref ? pref.incorporation_type_mc_id : SOFT,
      allowHard: opt.meta.allowHard,
    };
  });

  const preferredBookingTypePref = getPreference(PREFERRED_BOOKING_TYPE_PREF);
  const excludedBookingTypePref = getPreference(EXCLUDED_BOOKING_TYPE_PREF);

  return {
    preferences,
    preferredBookingTypes: preferredBookingTypePref
      ? preferredBookingTypePref.preference_value
          .split(',')
          .map((val) => Number(val))
      : [],
    excludedBookingTypes: excludedBookingTypePref
      ? excludedBookingTypePref.preference_value
          .split(',')
          .map((val) => Number(val))
      : [],
  };
}

function transformRequest(preferenceData) {
  const { preferences, preferredBookingTypes, excludedBookingTypes } =
    preferenceData;

  const returnData = [];

  preferences
    .filter((pref) => pref.preferenceValue)
    .forEach((pref) => {
      returnData.push({
        preference_type_mc_id: pref.preferenceType,
        incorporation_type_mc_id: pref.incorporationType,
      });
    });

  if (preferredBookingTypes.length) {
    returnData.push({
      preference_type_mc_id: PREFERRED_BOOKING_TYPE_PREF,
      preference_value: preferredBookingTypes.toString(),
      incorporation_type_mc_id: SOFT,
    });
  }

  if (excludedBookingTypes.length) {
    returnData.push({
      preference_type_mc_id: EXCLUDED_BOOKING_TYPE_PREF,
      preference_value: excludedBookingTypes.toString(),
      incorporation_type_mc_id: HARD,
    });
  }

  return {
    preference: returnData,
  };
}

async function get(userName) {
  const url = `api/cw/${userName}/preference`;
  const response = await axios.get(url);

  return transformResponse(response.data.results);
}

async function post(userName, preferences) {
  const url = `api/cw/${userName}/preference`;
  const requestData = transformRequest(preferences);
  await axios.post(url, requestData);
}

export { get, post, transformResponse, transformRequest };
