import axios from 'axios';
import { SERVER_ADDRESS } from 'hcms-constants/APP_PATHS';
import { currentUser, logout } from 'auth-utils';

axios.defaults.baseURL = SERVER_ADDRESS;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Authorization = `Token ${currentUser.token}`;
axios.interceptors.response.use(undefined, async (error) => {
  if (error.response.status === 403) {
    if (error.config.url.indexOf('api/auth') === -1) {
      logout(true);
    }
  }
  throw error;
});
