import axios from 'axios';
import { emptyToNull } from 'global-utils';
import moment from 'moment';

function transformResponse(medication) {
  return {
    medicationId: medication.medication_id,
    medicationType: medication.medication_type_mc_id,
    medicationRoute: medication.medication_route_mc_id,
    medicationName: medication.medication_name,
    strength: medication.strength,
    administrationDetails: medication.administration_details,
    medicationImageId: medication.medication_doc_id
      ? [medication.medication_doc_id]
      : [],
    proofDocId: medication.proof_doc_id,
    dosage: medication.dosage,
    dosageUnit: medication.dosage_unit_mc_id,
    createdDate: moment(medication.last_modified_date),
    createdBy: medication.last_modified_by,

    weekdays: medication.weekdays,
    skills: medication.skills_required ?? [],
    slots: medication.slots.map((slot) => ({
      slotId: slot.medication_slot_id,
      startTime: slot.start_time.split(':').slice(0, 2).join(':'),
      endTime: slot.end_time.split(':').slice(0, 2).join(':'),
    })),
    slotGracePeriod: medication.slot_grace_period,
    mealPreference: medication.meal_preference_mc_id,
    prescriptionStart: moment(medication.start_date),
    prescriptionEnd: moment(medication.end_date),
    prescriptionDuration: Math.ceil(
      moment(medication.end_date).diff(medication.start_date, 'days', true),
    ),
    terminationProof: medication.termination_proof_doc_id,
    terminationDate: medication.terminated_on
      ? moment(medication.terminated_on)
      : '',
    terminationRemark: medication.termination_remark,
    actualEnd: moment(medication.terminated_on || medication.end_date),
  };
}
function transformRequest(medication) {
  return emptyToNull({
    medication: {
      medication_type_mc_id: medication.medicationType,
      medication_route_mc_id: medication.medicationRoute,
      medication_name: medication.medicationName,
      strength: medication.strength,
      administration_details: medication.administrationDetails,
      medication_doc_id: medication.medicationImageId
        ? medication.medicationImageId[0]
        : '',
      proof_doc_id: medication.proofDocId,
      dosage: medication.dosage,
      dosage_unit_mc_id: medication.dosageUnit,

      weekdays: medication.weekdays.sort(),
      skills_required: medication.skills,
      slots: medication.slots.map((slot) => ({
        start_time: slot.startTime,
        end_time: slot.endTime,
      })),
      slot_grace_period: medication.slotGracePeriod,
      meal_preference_mc_id: medication.mealPreference,
      start_date: medication.prescriptionStart,
      end_date: moment(medication.prescriptionStart)
        .add(medication.prescriptionDuration - 1, 'days')
        .endOf('day'),

      termination_proof_doc_id: medication.terminationProof,
      terminated_on: medication.terminationDate,
      termination_remark: medication.terminationRemark,
    },
  });
}

async function getAll(userName, params) {
  const url = `/api/su/${userName}/medication`;

  const response = await axios.get(url, {
    params,
  });

  return response.data.results.map(transformResponse);
}

async function get(userName, medicationId) {
  const url = `/api/su/${userName}/medication/${medicationId}`;

  const response = await axios.get(url);

  return transformResponse({
    ...response.data.medication,
    medication_id: medicationId,
  });
}

async function post(userName, medication, params) {
  const url = `/api/su/${userName}/medication`;
  const request = transformRequest(medication);

  const response = await axios.post(url, request, { params });

  return response.data.medication_id;
}

async function put(userName, medicationId, medication, params) {
  const url = `/api/su/${userName}/medication/${medicationId}`;
  const request = transformRequest(medication);
  await axios.put(url, request, { params });
}

export { get, getAll, post, put, transformRequest, transformResponse };
