<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-btn icon @click="goBack()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>Change Availability</v-toolbar-title>
    </v-toolbar>
    <div class="pa-3">
      <v-layout class="mb-3">
        <v-flex xs6>
          <h5 class="subheading">
            <strong>Contract Hours</strong>
          </h5>
          <h4>{{ contractHours }} Hours</h4>
        </v-flex>
        <v-flex xs6>
          <h5 class="subheading">
            <strong>Specified Availability</strong>
          </h5>
          <h4>{{ editedDuration }} Hours</h4>
        </v-flex>
      </v-layout>
      <resource-timeline
        v-bind="resourceTimelineProps"
        @eventClick="showEditSlotModal"
        @addBtnClick="showAddSlotModal" />
    </div>
    <v-fab-transition>
      <v-btn absolute dark fab bottom right color="red" v-show="isEdited" @click="makeChangeRequest">
        <v-icon>save</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import ConfirmationModal from '@/views/Common/ConfirmationModal.vue';
import { getOverlap } from 'global-utils';
import { availabilityStatusUtils, biweeklyUtils, employmentStatusUtils } from 'hcms-mc-utils';
import { getAll as getAvailabilities, post as postAvailability } from 'hcms-transforms/cw/availability';
import { getAll as getContracts } from 'hcms-transforms/cw/employment';
import differenceBy from 'lodash.differenceby';
import intersectionBy from 'lodash.intersectionby';
import moment from 'moment';
import { ResourceTimeline } from 'vue-resource-timeline';
import { VFabTransition, VToolbar, VToolbarTitle } from 'vuetify/lib';
import ConfirmAvailability from './ConfirmAvailability.vue';
import SlotDetails from './SlotDetails.vue';

const toSlotIdentity = (slot) => `${slot.day} ${slot.startTime} ${slot.endTime} ${slot.biweekly}`;

const CURRENT_CONTRACT = employmentStatusUtils.DICT.CURRENT;

const { ODD_WEEK, EVEN_WEEK } = biweeklyUtils.DICT;
const { CURRENT } = availabilityStatusUtils.DICT;

const currentQuery = {
  status: CURRENT,
};

const empty = {
  slots: [],
};

export default {
  name: 'ChangeAvailability',

  components: {
    ResourceTimeline,
    VToolbar,
    VToolbarTitle,
    VFabTransition,
  },

  data() {
    return {
      loading: false,
      currentAvailability: this.cloneJSON(empty),
      tempAvailability: this.cloneJSON(empty),
      currentContract: '',
    };
  },

  computed: {
    contractHours() {
      if (!this.currentContract) {
        return 0;
      }
      return this.currentContract.availabilityHr;
    },
    editedDuration() {
      const slotToDuration = (slot) =>
        Number(moment(slot.endTime, 'HH:mm').diff(moment(slot.startTime, 'HH:mm'), 'minutes'));
      const durationInMinutes = this.tempAvailability.slots.reduce((acc, slot) => acc + slotToDuration(slot), 0);
      return durationInMinutes / 60;
    },
    unchangedSlots() {
      return intersectionBy(this.tempAvailability.slots, this.currentAvailability.slots, toSlotIdentity);
    },
    beforeSlots() {
      return differenceBy(this.currentAvailability.slots, this.unchangedSlots, toSlotIdentity);
    },
    afterSlots() {
      return differenceBy(this.tempAvailability.slots, this.unchangedSlots, toSlotIdentity);
    },
    changedSlots() {
      return [...this.afterSlots, ...this.beforeSlots];
    },
    isEdited() {
      return this.changedSlots.length > 0;
    },
    resourceTimelineProps() {
      const getBiweeklyClass = (biweekly) => {
        if (biweekly === ODD_WEEK) {
          return 'rt__event--odd';
        }
        if (biweekly === EVEN_WEEK) {
          return 'rt__event--even';
        }
        return '';
      };
      const getResourceIdSuffixer =
        (suffix = '') =>
        (slot) => ({
          origSlot: slot,
          resourceId: `${slot.day}${suffix}`,
          start: slot.startTime,
          end: slot.endTime,
          title: `${slot.startTime} - ${slot.endTime}`,
          class: getBiweeklyClass(slot.biweekly),
        });
      const weekDays = moment.weekdaysShort(true);

      const unchangedClass = 'rt__resource--success-dull rt__resource--active';
      const beforeClass = 'rt__resource--danger';
      const afterClass = 'rt__resource--success rt__resource--active';

      const unchangedSuffix = '_unchanged';
      const beforeSuffix = '_before';
      const afterSuffix = '_after';
      const unchangedSuffixer = getResourceIdSuffixer(unchangedSuffix);
      const beforeSuffixer = getResourceIdSuffixer(beforeSuffix);
      const afterSuffixer = getResourceIdSuffixer(afterSuffix);

      const events = [];
      const resources = [];

      weekDays.forEach((day, index) => {
        // * Check if a day has changes
        const isChange = this.changedSlots.find((slot) => slot.day === index);

        // * If a day doesn't have changes, show it as unchanged, otherwise show before & after
        if (!isChange) {
          resources.push({
            id: `${index}${unchangedSuffix}`,
            title: day,
            day: index,
            class: unchangedClass,
          });
          const dayEvents = this.tempAvailability.slots.filter((slot) => slot.day === index).map(unchangedSuffixer);
          events.push(...dayEvents);
        } else {
          resources.push(
            {
              id: `${index}${beforeSuffix}`,
              title: day,
              day: index,
              class: beforeClass,
            },
            {
              id: `${index}${afterSuffix}`,
              title: '',
              day: index,
              class: afterClass,
            },
          );
          const dayBeforeEvents = this.currentAvailability.slots
            .filter((slot) => slot.day === index)
            .map(beforeSuffixer);
          const dayAfterEvents = this.tempAvailability.slots.filter((slot) => slot.day === index).map(afterSuffixer);

          events.push(...dayBeforeEvents, ...dayAfterEvents);
        }
      });

      return {
        resources,
        events,
        showEmptyResource: true,
      };
    },
  },

  methods: {
    async getCurrentContract() {
      const params = {
        employment_status: CURRENT_CONTRACT,
      };

      const contracts = await getContracts(this.userName, params);

      this.currentContract = contracts[0];
    },
    async getAvailabilities() {
      this.loading = true;
      try {
        const currAvails = await getAvailabilities(this.userName, currentQuery);

        this.currentAvailability = currAvails.length ? currAvails[0] : this.cloneJSON(empty);
        this.resetChanges();
      } catch (err) {
        this.$notify(err, 'error');
      }
      this.loading = false;
    },

    async showAddSlotModal(resource) {
      const day = resource.id;

      try {
        const response = await this.$showModal({
          component: SlotDetails,
          props: {
            prevSlot: {
              day,
            },
          },
        });

        if (response && response.newSlot) {
          response.newSlot.id = this.generateRandomNumbers()[0];
          this.addSlot(response.newSlot);
        }
      } catch (err) {
        this.$notify(err, 'error');
      }
    },

    async showEditSlotModal(event) {
      if (event.resourceId.includes('before')) {
        return;
      }
      const slot = event.origSlot;
      const response = await this.$showModal({
        component: SlotDetails,
        props: {
          prevSlot: slot,
        },
      });

      if (!response) {
        return;
      }

      this.tempAvailability.slots = this.tempAvailability.slots.filter((currSlot) => currSlot !== slot);

      if (response.newSlot) {
        response.newSlot.id = this.generateRandomNumbers()[0];
        this.addSlot(response.newSlot);
      }
    },

    addSlot(slot) {
      const isOverlap = this.tempAvailability.slots.reduce((overlapping, checkSlot) => {
        if (overlapping) {
          return overlapping;
        }

        if (slot.day !== checkSlot.day) {
          return false;
        }

        return getOverlap([checkSlot.startTime, checkSlot.endTime], [slot.startTime, slot.endTime]);
      }, false);

      if (isOverlap) {
        throw new Error('The slot overlaps with existing slots');
      }

      this.tempAvailability.slots.push(slot);
    },

    resetChanges() {
      this.tempAvailability.slots = this.cloneJSON(this.currentAvailability.slots);
    },

    async makeChangeRequest() {
      const isEqual = this.equalJSON(this.currentAvailability.slots, this.tempAvailability.slots);

      if (isEqual) {
        this.$notify('Please edit the slots', 'error');
        return;
      }
      try {
        const isEdit = typeof this.currentAvailability.availabilityId === 'number';

        if (this.editedDuration < this.contractHours) {
          this.$notify('Availability hours must be greater than Contract Hours', 'error');
          return;
        }

        const confirmation = await this.$showModal({
          component: ConfirmAvailability,
          props: {
            title: 'Request Changes',
            selectDate: isEdit,
          },
        });

        if (!confirmation) {
          return;
        }

        const effectiveFrom = isEdit ? moment(confirmation.effectiveFrom) : moment().startOf('day');

        await postAvailability(this.userName, {
          slots: this.tempAvailability.slots,
          effectiveFrom,
          prevAvailabilityId: this.currentAvailability.availabilityId,
        });
        this.currentAvailability.slots = this.cloneJSON(this.tempAvailability.slots);
        this.$notify('The changes have been proposed', 'success');
        this.goBack();
      } catch (err) {
        this.$notify('Could not propose changes, please contact admin', 'danger');
      }
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.isEdited) {
      next();
      return;
    }

    const response = await this.$showModal({
      component: ConfirmationModal,
      props: {
        description: "Your data hasn't been saved!\nDo you want to proceed?",
      },
    });
    if (response) {
      next();
    }
  },

  created() {
    this.getCurrentContract();
    this.getAvailabilities();
  },
};
</script>
