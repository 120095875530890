import moment from 'moment';
import axios from 'axios';

function transformResponse(slot) {
  return {
    cwUserName: slot.careworker,
    trainingId: slot.training_id,
    trainingType: slot.training_template_id,
    trainingCentre: slot.training_centre,
    start: moment(slot.start),
    end: moment(slot.end),
    arrivalTime: slot.arrival_time.split(':').slice(0, 2).join(':'),
  };
}

async function getAll(params) {
  const url = 'api/scheduled_training_slot';
  const response = await axios.get(url, { params });

  return response.data.results.map(transformResponse);
}

export { getAll };
