<template>
  <div v-loading="loading">
    <v-toolbar v-if="showNav" flat color="primary" dark>
      <v-icon>work_off</v-icon>
      <v-toolbar-title>Leave</v-toolbar-title>
      <v-spacer></v-spacer>
      <notification-bell></notification-bell>
    </v-toolbar>
    <leave-summary />
    <v-divider />
    <leave-history />
  </div>
</template>

<script>
import NotificationBell from 'components/NotificationBell.vue';
import { VToolbar, VToolbarTitle } from 'vuetify/lib';
import LeaveHistory from './LeaveHistory.vue';
import LeaveSummary from './LeaveSummary.vue';

export default {
  name: 'Availability',
  components: {
    NotificationBell,
    VToolbar,
    VToolbarTitle,
    LeaveSummary,
    LeaveHistory,
  },

  data() {
    return {
      loading: false,
    };
  },
};
</script>
