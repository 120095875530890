<template>
  <div v-loading="loading">
    <v-expansion-panel v-if="bookingList.length > 0" popout>
      <v-expansion-panel-content v-for="(booking, i) in bookingList" :key="i">
        <template v-slot:header>
          <v-layout justify-space-between>
            <div>
              {{ booking.bookingTitle }},
              {{ booking.suName }}
              <v-icon v-if="booking.isNew" class="success--text">new_releases</v-icon>
            </div>
          </v-layout>
        </template>

        <div>
          <v-layout row class="mb-2">
            <v-flex xs4>
              <v-avatar :size="100">
                <img :src="booking.profileImageUrl" />
              </v-avatar>
            </v-flex>
            <v-flex xs8>
              <v-flex layout align-center justify-space-between>
                <div>
                  <strong>Address</strong>
                  <div>
                    {{ booking.suAddress }}
                  </div>
                </div>
                <v-btn class="ma-0" icon target="_blank" :href="`https://www.google.com/maps?q=${booking.suPostcode}`">
                  <v-icon>directions</v-icon>
                </v-btn>
              </v-flex>
              <div class="mt-2" v-if="booking.otherCwName">
                <strong>Other CW</strong>
                <div>
                  {{ booking.otherCwName }}
                </div>
              </div>
            </v-flex>
          </v-layout>

          <v-layout row class="mb-2" v-show="false">
            <v-flex xs4>
              <strong>Consumables:</strong>
            </v-flex>
            <v-flex xs8> </v-flex>
          </v-layout>

          <v-layout justify-space-between class="mt-3">
            <v-flex>
              <router-link
                tag="span"
                :to="{
                  name: 'Booking Details',
                  params: { bookingId: booking.bookingId },
                }">
                <v-btn flat small class="ma-0" color="primary">
                  <v-icon class="mr-1">info</v-icon>
                  View Details
                </v-btn>
              </router-link>
            </v-flex>
          </v-layout>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-container fluid v-else>
      <v-layout class="grey--text" align-center justify-center> No Bookings </v-layout>
    </v-container>
  </div>
</template>

<script>
import { getAll as getAddresses } from 'hcms-transforms/user/address';
import { get as getSu } from 'hcms-transforms/su';
import { get as getUser } from 'hcms-transforms/user_list';
import { titleUtils } from 'hcms-mc-utils';

import { VAvatar, VExpansionPanel, VExpansionPanelContent } from 'vuetify/lib';

import { get as getAttachment } from 'hcms-transforms/attachment';
import { currentUser } from 'auth-utils';

async function getName(userName) {
  if (!userName) {
    return '';
  }
  const user = await getUser(userName);
  return `${titleUtils.toLabel(user.title)} ${user.firstName} ${user.lastName}`;
}

export default {
  name: 'Bookings',
  model: {
    prop: 'value',
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isSameDay: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    VAvatar,
    VExpansionPanel,
    VExpansionPanelContent,
  },

  data() {
    return {
      loading: false,
      bookingList: [],
    };
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
    },
  },

  methods: {
    async formatData(booking) {
      const addressPromise = getAddresses(booking.suUserName, {
        is_current_address: true,
      });
      const suPromise = getSu(booking.suUserName);
      const otherCwPromises = booking.bookingAllocation
        .filter((allocation) => allocation.cwUserName !== currentUser.userName)
        .map((allocation) => getName(allocation.cwUserName));

      await Promise.all([addressPromise, suPromise, ...otherCwPromises]);

      const addresses = await addressPromise;
      const serviceUser = await suPromise;
      const address = addresses[0];
      const { profile } = serviceUser;
      const suName = `${titleUtils.toLabel(profile.title)} ${profile.firstName} ${profile.lastName}`;

      const otherCwNames = await Promise.all(otherCwPromises);

      let profileImageUrl = '/img/no-photo-man.png';
      const suProfileImageId = profile.profileImage;

      if (suProfileImageId) {
        const profileImageAttachment = await getAttachment(suProfileImageId);
        profileImageUrl = profileImageAttachment.url;
      }

      const bookingTitle = this.isSameDay
        ? `${this.formatTime(booking.start)} - ${this.formatTime(booking.end)}`
        : `${booking.start.format('DD MMM')} - ${this.formatTime(booking.start)}`;

      const formattedData = {
        ...booking,
        bookingTitle,
        suName,
        isNew: booking.lastModified.isAfter(currentUser.lastLogin),
        suAddress: `${address.street1 || ''}, ${address.street2 || ''} ${address.postCode || ''}`,
        suPostcode: address.postCode,
        profileImageUrl,
        otherCwName: otherCwNames.join(),
      };

      return formattedData;
    },

    async init() {
      this.loading = true;
      const promises = this.model.map(this.formatData, this);
      this.bookingList = await Promise.all(promises);
      this.loading = false;
    },
  },

  async created() {
    await this.init();
  },

  watch: {
    async model() {
      await this.init();
    },
  },
};
</script>
